import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Tab, Nav, Button } from "react-bootstrap";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import AtlanticOcean from "../../../../assets/images/banner/blog/atlantic-ocean.png";
import IndianOcean from "../../../../assets/images/banner/blog/indian-ocean.png";
import PacificOcean from "../../../../assets/images/banner/blog/pacific-ocean.png";
import SouthernOcean from "../../../../assets/images/banner/blog/southern-ocean.png";
import ArcticOcean from "../../../../assets/images/banner/blog/arctic-ocean.png";
import RedSea from "../../../../assets/images/banner/blog/red-sea.png";
import AdriaticSea from "../../../../assets/images/banner/blog/adriatic-sea.png";
import AegeanSea from "../../../../assets/images/banner/blog/aegean-sea.png";
import ArrowBack from "../../../../assets/images/icons/arrow-back.png";

const Blog = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  const navItems = [
    {
      eventKey: "first",
      imgSrc: AtlanticOcean,
      altText: "atlantic ocean",
      label: "The Atlantic Ocean",
    },
    {
      eventKey: "second",
      imgSrc: IndianOcean,
      altText: "indian ocean",
      label: "Indian Ocean",
    },
    {
      eventKey: "thired",
      imgSrc: PacificOcean,
      altText: "pacific ocean",
      label: "Pacific Ocean",
    },
    {
      eventKey: "fourth",
      imgSrc: SouthernOcean,
      altText: "southern ocean",
      label: "Southern Ocean",
    },
    {
      eventKey: "fifth",
      imgSrc: ArcticOcean,
      altText: "arctic ocean",
      label: "Arctic Ocean",
    },
    {
      eventKey: "sixsth",
      imgSrc: RedSea,
      altText: "red sea",
      label: "Red Sea",
    },
    {
      eventKey: "seven",
      imgSrc: AdriaticSea,
      altText: "adriatic sea",
      label: "Adriatic Sea",
    },
    {
      eventKey: "eight",
      imgSrc: AegeanSea,
      altText: "aegean sea",
      label: "Aegean Sea",
    },
  ];
  const [show, setShow] = useState(true);
  const isMobile = window.screen.width > 767.98 ? false : true;
  const handleClick = () => {
    if (isMobile) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  const [active, setActive] = useState("first");

  const handleShow = () => {
    setShow((prev) => !prev);
    setActive(false);
  };
  console.log("Active", active);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="promotions">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={!isMobile && active}
          >
            <div className="tournaments">
              {show && (
                <div
                  className={show ? "tournaments-info d-block" : " d-none d-m"}
                >
                  <div className="header-title">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate(-1)}
                      className="d-md-none"
                    >
                      <img src={ArrowBack} alt="back" />
                    </a>
                    <h2>Blog</h2>
                  </div>

                  <div className="tournaments-promotions">
                    <Nav variant="pills">
                      {navItems.map((item, index) => (
                        <Nav.Item key={index} onClick={handleClick}>
                          <Nav.Link eventKey={item.eventKey}>
                            <img src={item.imgSrc} alt={item.altText} />
                            <h6>{item.label}</h6>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </div>
              )}

              <div className={show ? "tournaments-details" : ""}>
                <Tab.Content>
                  {navItems.map((item, index) => (
                    <Tab.Pane eventKey={item.eventKey} key={index}>
                      <div className="header-title">
                        {isMobile && (
                          <a
                            href="javascript:void(0)"
                            onClick={handleShow}
                            className="d-md-none"
                          >
                            <img src={ArrowBack} alt="back" />
                          </a>
                        )}
                        <h2> {item.label}</h2>
                      </div>
                      <div className="img-box">
                        <img src={item.imgSrc} alt={item.altText} />
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </>
  );
};

export default Blog;
