export const spribeData = {
  aviator: {
    redirectUrl: "/casino/spribe/aviator",
    code: "aviator",
    casino: "spribe",
    provider: "aviator",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/spribe/Aviator.gif",
    alt: "",
    name: "Aviator",
    providerName: "Spribe",
  },
  mines: {
    redirectUrl: "/casino/spribe/mines",
    code: "mines",
    casino: "spribe",
    provider: "mines",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/spribe/Mines.jpg",
    alt: "",
    name: "Mines",
    providerName: "Spribe",
  },
  goal: {
    redirectUrl: "/casino/spribe/goal",
    code: "goal",
    casino: "spribe",
    provider: "goal",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/spribe/Goal.jpg",
    alt: "",
    name: "Goal",
    providerName: "Spribe",
  },
  dice: {
    redirectUrl: "/casino/spribe/dice",
    code: "dice",
    casino: "spribe",
    provider: "dice",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/spribe/Dice.jpg",
    alt: "",
    name: "Dice",
    providerName: "Spribe",
  },
  plinko: {
    redirectUrl: "/casino/spribe/plinko",
    code: "plinko",
    casino: "spribe",
    provider: "plinko",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/spribe/Plinko.jpg",
    alt: "",
    name: "Plinko",
    providerName: "Spribe",
  },
  miniroulette: {
    redirectUrl: "/casino/spribe/miniroulette",
    code: "mini-roulette",
    casino: "spribe",
    provider: "mini-roulette",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/spribe/Mini-Roulette.jpg",
    alt: "",
    name: "Mini Roulette",
    providerName: "Spribe",
  },
  hilo: {
    redirectUrl: "/casino/spribe/hilo",
    code: "hi-lo",
    casino: "spribe",
    provider: "hi-lo",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/spribe/Hilo.jpg",
    alt: "",
    name: "Hilo",
    providerName: "Spribe",
  },
};
