export const auraGames = {
  improvedExperience: {
    Card2Teenpatti: {
      code: "67660",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "2 Card Teenpatti",
      name: "2 Card Teenpatti",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/2-card-teenpatti.jpg",
      redirectUrl: "/casino/aura-2-card-teenpatti",
    },
    Up7Down: {
      code: "98789",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "7 Up Down",
      name: "7 Up Down",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/7-up-down.jpg",
      redirectUrl: "/casino/aura-7-up-down",
    },
    AmarAkbarAnthony: {
      code: "98791",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Amar Akbar Anthony",
      name: "Amar Akbar Anthony",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/amar-akbar-anthony.jpg",
      redirectUrl: "/casino/aura-amar-akbar-anthony",
    },
    AndarBahar: {
      code: "87564",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Andar Bahar",
      name: "Andar Bahar",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/andar-bahar.jpg",
      redirectUrl: "/casino/aura-andar-bahar",
    },
    BollywoodCasino: {
      code: "67570",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Bollywood Casino",
      name: "Bollywood Casino",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/bollywood-casino.jpg",
      redirectUrl: "/casino/aura-bollywood-casino",
    },
    CasinoWar: {
      code: "67580",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino War",
      name: "Casino War",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/casino-war.jpg",
      redirectUrl: "/casino/aura-casino-war",
    },
    DragonTiger: {
      code: "98790",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Dragon Tiger",
      name: "Dragon Tiger",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/dragon-tiger.jpg",
      redirectUrl: "/casino/aura-dragon-tiger",
    },
    DreamCatcher: {
      code: "98567",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Dream Catcher",
      name: "Dream Catcher",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/dream-catcher.jpg",
      redirectUrl: "/casino/aura-dream-catcher",
    },
    HiLow: {
      code: "56968\n",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Hi Low",
      name: "Hi Low",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/hi-low.jpg",
      redirectUrl: "/casino/aura-hi-low",
    },
    MuflisTeenpatti: {
      code: "67600",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Muflis Teenpatti",
      name: "Muflis Teenpatti",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/muflis-teenpatti.jpg",
      redirectUrl: "/casino/aura-muflis-teenpatti",
    },
    Poker2020: {
      code: "67567",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker 20-20",
      name: "Poker 20-20",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/poker-20-20.jpg",
      redirectUrl: "/casino/aura-poker-20-20",
    },
    QueenRace: {
      code: "67620",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Queen Race",
      name: "Queen Race",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/queen-race.jpg",
      redirectUrl: "/casino/aura-queen-race",
    },
    Roulette: {
      code: "98788",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Roulette",
      name: "Roulette",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/roulette.jpg",
      redirectUrl: "/casino/aura-roulette",
    },
    Sicbo: {
      code: "98566",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Sicbo",
      name: "Sicbo",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/sicbo.jpg",
      redirectUrl: "/casino/aura-sicbo",
    },
    TeenpattiOneDay: {
      code: "56767",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "teenpatti one day",
      name: "Teenpatti One Day",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/teenpatti-one-day.jpg",
      redirectUrl: "/casino/aura-teenpatti-1-Day",
    },
    TeenpattiT20: {
      code: "56768",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti T20",
      name: "Teenpatti T20",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/teenpatti-t20.jpg",
      redirectUrl: "/casino/aura-teenpatti-t20",
    },
    TeenpattiTest: {
      code: "67630",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Test",
      name: "Teenpatti Test",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/teenpatti-test.jpg",
      redirectUrl: "/casino/aura-teenpatti-test",
    },
  },
  cardGames: {
    Cards3Judgement: {
      code: "67670",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "3 Cards Judgement",
      name: "3 Cards Judgement",
      providerName: "aura",
      imgUrl: "./images/aura/card/3-cards-judgement.jpg",
      redirectUrl: "/casino/aura-3-cards-judgement",
    },
    Card29Baccarat: {
      code: "67690",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "29 Card Baccarat",
      name: "29 Card Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/card/29-card-baccarat.jpg",
      redirectUrl: "/casino/aura-29-card-baccarat",
    },
    Baccarat: {
      code: "92038",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Baccarat",
      name: "Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/card/baccarat.jpg",
      redirectUrl: "/casino/aura-baccarat",
    },
    CasinoMeter: {
      code: "67575",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino Meter",
      name: "Casino Meter",
      providerName: "aura",
      imgUrl: "./images/aura/card/casino-meter.jpg",
      redirectUrl: "/casino/aura-casino-meter",
    },
    Matka: {
      code: "92037",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Matka",
      name: "Matka",
      providerName: "aura",
      imgUrl: "./images/aura/card/matka.jpg",
      redirectUrl: "/casino/aura-matka",
    },
    Poker: {
      code: "67564",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker",
      name: "Poker",
      providerName: "aura",
      imgUrl: "./images/aura/card/poker.jpg",
      redirectUrl: "/casino/aura-poker",
    },
    Race2020: {
      code: "90100",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race 20-20",
      name: "Race 20-20",
      providerName: "aura",
      imgUrl: "./images/aura/card/race-20-20.jpg",
      redirectUrl: "/casino/aura-race-20-20",
    },
    Raceto17: {
      code: "67710",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race to 17",
      name: "Race to 17",
      providerName: "aura",
      imgUrl: "./images/aura/card/race-to-17.jpg",
      redirectUrl: "/casino/aura-race-to-17",
    },
    Sixplayerpoker: {
      code: "67565",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Six player poker",
      name: "Six player poker",
      providerName: "aura",
      imgUrl: "./images/aura/card/six-player-poker.jpg",
      redirectUrl: "/casino/aura-six-player-poker",
    },
    SuperOver: {
      code: "67720",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Super Over",
      name: "Super Over",
      providerName: "aura",
      imgUrl: "./images/aura/card/super-over.jpg",
      redirectUrl: "/casino/aura-sper-over",
    },
    TeenpattiOpen: {
      code: "67640",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Open",
      name: "Teenpatti Open",
      providerName: "aura",
      imgUrl: "./images/aura/card/teenpatti-open.jpg",
      redirectUrl: "/casino/aura-teenpatti-open",
    },
    TheTrap: {
      code: "67680",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "The Trap",
      name: "The Trap",
      providerName: "aura",
      imgUrl: "./images/aura/card/the-trap.jpg",
      redirectUrl: "/casino/aura-the-trap",
    },
    Trio: {
      code: "67610",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Trio",
      name: "Trio",
      providerName: "aura",
      imgUrl: "./images/aura/card/trio.jpg",
      redirectUrl: "/casino/aura-trio",
    },
  },
  virtualCardGames: {
    Up7NDownVirtual: {
      code: "98793",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "7 up & Down (Virtual)",
      name: "7 up & Down (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/7-up-&-down.jpg",
      redirectUrl: "/casino/aura-7-up-&-down-virtual",
    },
    cards32casinoVirtual: {
      code: "56966",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "32 cards casino (Virtual)",
      name: "32 cards casino (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/32-cards-casino.jpg",
      redirectUrl: "/casino/aura-32-cards-casino-virtual",
    },
    AmarAkbarAnthonyVirtual: {
      code: "98795",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Amar Akbar Anthony (Virtual)",
      name: "Amar Akbar Anthony (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/amar-akbar-anthony.jpg",
      redirectUrl: "/casino/aura-amar-akbar-anthony-virtual",
    },
    AndarBaharVirtual: {
      code: "87565",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Andar Bahar (Virtual)",
      name: "Andar Bahar (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/andar-bahar.jpg",
      redirectUrl: "/casino/aura-andar-bahar-virtual",
    },
    BaccaratVirtual: {
      code: "92039",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Baccarat (Virtual)",
      name: "Baccarat (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/baccarat.jpg",
      redirectUrl: "/casino/aura-baccarat-virtual",
    },
    BollywoodCasinoVirtual: {
      code: "67571",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Bollywood Casino (Virtual)",
      name: "Bollywood Casino (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/bollywood-casino.jpg",
      redirectUrl: "/casino/aura-bollywood-casino-virtual",
    },
    CasinoMeterVirtual: {
      code: "67576",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino Meter (Virtual)",
      name: "Casino Meter (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/casino-meter.jpg",
      redirectUrl: "/casino/aura-casino-meter-virtual",
    },
    CasinoWarVirtual: {
      code: "67581",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino War (Virtual)",
      name: "Casino War (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/casino-war.jpg",
      redirectUrl: "/casino/aura-casino-war-virtual",
    },
    DragonTigerVirtual: {
      code: "98794",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Dragon Tiger (Virtual)",
      name: "Dragon Tiger (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/dragon-tiger.jpg",
      redirectUrl: "/casino/aura-dragon-tiger-virtual",
    },
    HiLowVirtual: {
      code: "56969",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Hi Low (Virtual)",
      name: "Hi Low (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/hi-low.jpg",
      redirectUrl: "/casino/aura-hi-low-virtual",
    },
    MatkaVirtual: {
      code: "92036",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Matka (Virtual)",
      name: "Matka (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/matka.jpg",
      redirectUrl: "/casino/aura-matka-virtual",
    },
    MuflisTeenpattiVirtual: {
      code: "67601",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Muflis Teenpatti (virtual)",
      name: "Muflis Teenpatti (virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/muflis-teenpatti.jpg",
      redirectUrl: "/casino/aura-muflis-teenpatti-virtual",
    },
    Poker2020Virtual: {
      code: "67568",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker 20-20 (Virtual)",
      name: "Poker 20-20 (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/poker-20-20.jpg",
      redirectUrl: "/casino/aura-poker-20-20-virtual",
    },
    PokerVirtual: {
      code: "67563",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker  (Virtual)",
      name: "Poker  (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/poker.jpg",
      redirectUrl: "/casino/aura-poker-virtual",
    },
    QueenRaceVirtual: {
      code: "67621",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Queen Race (Virtual)",
      name: "Queen Race (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/queen-race.jpg",
      redirectUrl: "/casino/aura-queen-race-virtual",
    },
    Race2020Virtual: {
      code: "90101",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Race 20-20 (Virtual)",
      name: "Race 20-20 (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/race-20-20.jpg",
      redirectUrl: "/casino/aura-Race-20-20-virtual",
    },
    RouletteVirtual: {
      code: "98792",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Roulette (Virtual)",
      name: "Roulette (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/roulette.jpg",
      redirectUrl: "/casino/aura-roulette-virtual",
    },
    SixPlayerPokerVirtual: {
      code: "67566",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Six player poker (Virtual)",
      name: "Six player poker (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/six-player-poker.jpg",
      redirectUrl: "/casino/aura-six-player-poker-virtual",
    },
    TeenpattiOneDayVirtual: {
      code: "56766",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti One-Day (Virtual)",
      name: "Teenpatti One-Day (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/teenpatti-one-day.jpg",
      redirectUrl: "/casino/aura-teenpatti-one-day-virtual",
    },
    TeenpattiOpenVirtual: {
      code: "67641",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Open (virtual)",
      name: "Teenpatti Open (virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/teenpatti-open.jpg",
      redirectUrl: "/casino/aura-teenpatti-open-virtual",
    },
    TeenpattiT20Virtual: {
      code: "56769",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti T20 (Virtual)",
      name: "Teenpatti T20 (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/teenpatti-t20.jpg",
      redirectUrl: "/casino/aura-teenpatti-t20-virtual",
    },
    TeenpattiTestVirtual: {
      code: "67631",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Test (virtual)",
      name: "Teenpatti Test (virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/teenpatti-test.jpg",
      redirectUrl: "/casino/aura-teenpatti-test-virtual",
    },
    TrioVirtual: {
      code: "67611",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Trio (virtual)",
      name: "Trio (virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/trio-virtual.jpg",
      redirectUrl: "/casino/aura-trio-virtual",
    },
  },
  instantGames: {
    Aviator: {
      code: "67722",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Aviator",
      name: "Aviator",
      providerName: "aura",
      imgUrl: "./images/aura/instant/aviator.jpg",
      redirectUrl: "/casino/aura-aviator",
    },
    Blastoff: {
      code: "67722",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Blastoff",
      name: "Blastoff",
      providerName: "aura",
      imgUrl: "./images/aura/instant/blastoff.jpg",
      redirectUrl: "/casino/aura-blastoff",
    },
    CoinToss: {
      code: "70003",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Coin Toss",
      name: "Coin Toss",
      providerName: "aura",
      imgUrl: "./images/aura/instant/coin-toss.jpg",
      redirectUrl: "/casino/aura-coin-toss",
    },
    Cricket: {
      code: "70008",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Cricket",
      name: "Cricket",
      providerName: "aura",
      imgUrl: "./images/aura/instant/cricket.jpg",
      redirectUrl: "/casino/aura-cricket",
    },
    FortuneWheel: {
      code: "70001",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Fortune Wheel",
      name: "Fortune Wheel",
      providerName: "aura",
      imgUrl: "./images/aura/instant/fortune-wheel.jpg",
      redirectUrl: "/casino/aura-fortune-wheel",
    },
    Limbo: {
      code: "70004",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Limbo",
      name: "Limbo",
      providerName: "aura",
      imgUrl: "./images/aura/instant/limbo.jpg",
      redirectUrl: "/casino/aura-limbo",
    },
    SlotGame: {
      code: "70005",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Slot Game",
      name: "Slot Game",
      providerName: "aura",
      imgUrl: "./images/aura/instant/slot-game.jpg",
      redirectUrl: "/casino/aura-slot-game",
    },
    RockPaperScissors: {
      code: "70006",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Rock Paper Scissors",
      name: "Rock Paper Scissors",
      providerName: "aura",
      imgUrl: "./images/aura/instant/rock-paper-scissors.jpg",
      redirectUrl: "/casino/aura-rock-paper-scissors",
    },
    ClassicDice: {
      code: "70009",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Classic Dice",
      name: "Classic Dice",
      providerName: "aura",
      imgUrl: "./images/aura/instant/classic-dice.jpg",
      redirectUrl: "/casino/aura-classic-dice",
    },
    Mines: {
      code: "70011",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Mines",
      name: "Mines",
      providerName: "aura",
      imgUrl: "./images/aura/instant/mines.jpg",
      redirectUrl: "/casino/aura-mines",
    },
  },
};
