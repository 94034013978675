export const betgamesData = {
  Poker6: {
    redirectUrl: "/casino/qtechbetgamestv-6-poker",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/6-poker.jpg",
    alt: "6+ poker",
    name: "6+ Poker",
    providerName: "Qtech",
  },
  Baccarat: {
    redirectUrl: "/casino/qtechbetgamestv-baccarat",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/baccarat.jpg",
    alt: "baccarat",
    name: "Baccarat",
    providerName: "Qtech",
  },
  //   BetOnBaccarat: {
  //     redirectUrl: "/casino/qtechbetgamestv-bet-on-baccarat-u2121",
  //     code: "",
  //     casino: "BetGames",
  //     provider: "Qtech",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/betgames/bet-on-baccarat.jpg",
  //     alt: "bet on baccarat",
  //     name: "Bet On Baccarat",
  //     providerName: "Qtech",
  //   },
  BetOnPoker: {
    redirectUrl: "/casino/qtechbetgamestv-bet-on-poker",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/bet-on-poker.jpg",
    alt: "bet on poker",
    name: "Bet On Poker",
    providerName: "Qtech",
  },
  ClassicRoulette: {
    redirectUrl: "/casino/qtechbetgamestv-roulette-u6720",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/classic-roulette.jpg",
    alt: "classic roulette",
    name: "Classic Rroulette",
    providerName: "Qtech",
  },
  DiceDuel: {
    redirectUrl: "/casino/qtechbetgamestv-dice-duel",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/dice-duel.jpg",
    alt: "dice duel",
    name: "Dice Duel",
    providerName: "Qtech",
  },
  FootballGrid: {
    redirectUrl: "/casino/qtechbetgamestv-football-grid-u4845",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/football-grid.jpg",
    alt: "football grid",
    name: "Football Grid",
    providerName: "Qtech",
  },
  Lucky5: {
    redirectUrl: "/casino/qtechbetgamestv-lucky-5",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/lucky-5.jpg",
    alt: "lucky 5",
    name: "Lucky 5",
    providerName: "Qtech",
  },
  Lucky6: {
    redirectUrl: "/casino/qtechbetgamestv-lucky-6",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/lucky-6.jpg",
    alt: "lucky 6",
    name: "Lucky 6",
    providerName: "Qtech",
  },
  Lucky7: {
    redirectUrl: "/casino/qtechbetgamestv-lucky-7",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/lucky-7.jpg",
    alt: "lucky 7",
    name: "Lucky 7",
    providerName: "Qtech",
  },
  Skyward: {
    redirectUrl: "/casino/qtechbetgamestv-skyward",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/skyward.jpg",
    alt: "skyward",
    name: "Skyward",
    providerName: "Qtech",
  },
  WarOfBets: {
    redirectUrl: "/casino/qtechbetgamestv-war-of-bets",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/war-of-bets.jpg",
    alt: "war of bets",
    name: "War of Bets",
    providerName: "Qtech",
  },
  WheelOfFortune: {
    redirectUrl: "/casino/qtechbetgamestv-wheel-of-fortune",
    code: "",
    casino: "BetGames",
    provider: "Qtech",
    homeUrl: "http://localhost:3000/Casino",
    imgUrl: "./images/betgames/wheel-of-fortune.jpg",
    alt: "wheel of fortune",
    name: "Wheel of Fortune",
    providerName: "Qtech",
  },
};
