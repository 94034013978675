import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { forgotPassword, resetPassword } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Col, Button, Form } from "react-bootstrap";
import { APP_CONST } from "../../../config/const";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";
import { demoLogin, loginUser } from "../../../redux/auth/actions";
import { checkIfDemoUserExist } from "../../../redux/app/actions";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import MumbaiSports from "../../../assets/images/icons/mumbaisports.png";
import UserName from "../../../assets/images/icons/icon-user.png";
import PasswordIcon from "../../../assets/images/icons/icon-pass.png";
import PhoneInput from "react-phone-input-2";
import EgameLogo1 from "../../../assets/images/icons/foot/egame-logo1.png";
import EgameLogo2 from "../../../assets/images/icons/foot/egame-logo2.png";
import Plus18 from "../../../assets/images/icons/foot/plus18.png";
import Certified from "../../../assets/images/icons/foot/certified.jpeg";

import LoginBanner from "../../../assets/images/banner/login.jpg";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);
  const [mobileNumber, setMobileNumber] = useState("");
  const [changeModalContent, setChangeModalContent] = useState(true);
  const [otp, setOtpValue] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isDemoUserExist, setIsDemoUserExist] = useState(false);

  useEffect(() => {
    checkDemoUser();
  }, []);

  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const handleSubmitForgotPass = async (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            setIsOtpSent(true);
            // navigate("/reset-password");
            // handleClose();
            // handleModalShow(APP_CONST.AUTH_MODAL.RESET_PASSWORD);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };

  const handleSubmitResetPass = () => {
    setIsVerifying(true);

    if (otp == "" || password == "") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "All fields are mandatory");
    } else {
      const payload = {
        otp,
        password,
      };

      resetPassword(payload)
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password reset success");
          // navigate("/sign-in");
          // handleClose();
          // handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
          setChangeModalContent((prev) => !prev);
        })
        .catch((err) => {
          Toaster(
            APP_CONST.TOAST_TYPE.ERROR,
            err.response.data.message || "Request failed"
          );
          setIsVerifying(false);
        });
    }
  };

  const handleDemoLogin = () => {
    dispatch(demoLogin());
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal LoginModal LoginPage LoginScroll"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      >
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {changeModalContent ? (
          <div
            className={
              !changeModalContent ? `login-section` : `login-section active`
            }
          >
            <div className="LoginBanner">
              <img src={LoginBanner} alt="LoginBanner" />
            </div>
            <div className="Login-rightPart">
              <div className="form-steps">
                <Form>
                  <Form.Group
                    className="mb-2 position-relative"
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      // placeholder="Enter Username*"
                      name="username"
                      onChange={handleChange}
                      isInvalid={!!errors.username}
                    />
                    <label>
                      Log in <span>*</span>
                    </label>

                    {/* <img src={UserName} alt="UserName" className="InputImg1" /> */}
                    <Form.Control.Feedback type="invalid">
                      {errors?.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-1 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      // placeholder="Password*"
                      name="password"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    {/* <img
                      src={PasswordIcon}
                      alt="PasswordIcon"
                      className="InputImg"
                    /> */}
                    <label>
                      Password <span>*</span>
                    </label>

                    <span
                      className="icon"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors?.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-start">
                    <a
                      className="form-link"
                      onClick={() => {
                        // handleClose();
                        // handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                        setChangeModalContent((prev) => !prev);
                      }}
                    >
                      Forgot password?
                    </a>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                  {(isDemoUserExist && (
                    <Button
                      variant="primary"
                      onClick={handleDemoLogin}
                      className="sign-in demologinbtn"
                    >
                      Login with Demo Id
                    </Button>
                  )) ||
                    null}
                </Form>

                <div className="sign-up signupsection">
                  Don't have an account{" "}
                  <a
                    onClick={() => {
                      // !isMobile
                      // ?
                      handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                      // : navigate("/sign-in");
                    }}
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              changeModalContent
                ? `login-section`
                : `login-section ForgotPasswordSection`
            }
          >
            <div className="LoginBanner">
              <img src={LoginBanner} alt="LoginBanner" />
            </div>
            <div className="Login-rightPart">
              <div className="form-steps mb-0">
                <Form onSubmit={handleSubmitForgotPass}>
                  <div className="get-otp">
                    <Form.Group
                      className="mb-3 position-relative"
                      controlId="formBasicPassword"
                    >
                      <label>Phone Num</label>
                      <PhoneInput
                        country={"in"}
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        onlyCountries={["in"]}
                        inputStyle={{
                          width: "100%",
                          lineHeight: 1.5,
                          padding: "0.7rem 0.75rem",
                          borderColor: "#26324B",
                          fontSize: "0.86rem",
                          minHeight: "41px",
                          paddingLeft: "50px",
                        }}
                        onChange={(phone) => {
                          // alert("phone", phone);
                          // alert(phone);
                          setMobileNumber(phone);
                        }}
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      // onClick={handleSubmitForgotPass}
                    >
                      Get OTP
                    </Button>
                  </div>
                </Form>
                <Form className="mt-5">
                  <Form.Group className="mb-3 position-relative" controlId="">
                    <Form.Control
                      type="text"
                      // placeholder="Enter OTP*"
                      onChange={(e) => setOtpValue(e.target.value)}
                      autoComplete="off"
                    />
                    <label>Enter OTP</label>
                  </Form.Group>
                  <Form.Group className="mb-3 position-relative" controlId="">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      // placeholder="Enter new password"
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="new-password"
                    />
                    <label>Enter new password</label>
                    <span
                      className="icon"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                    </span>
                  </Form.Group>

                  <Button
                    variant="primary"
                    onClick={handleSubmitResetPass}
                    disabled={!isOtpSent}
                  >
                    {!isVerifying ? "SUBMIT" : "SUBMITTING"}
                  </Button>
                </Form>
                <a
                  className="mt-3 form-link"
                  href="javascript:void(0)"
                  onClick={() => setChangeModalContent((prev) => !prev)}
                >
                  Back To Login
                </a>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
