import React from "react";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import { spribeData } from "../../../../lib/data/spripe.data";
import { useSelector } from "react-redux";
import PlayIcon from "../../../../assets/images/icons/play.png";
import BackIcon from "../../../../assets/images/icons/arrow-back.png";

import { useNavigate } from "react-router-dom";

const Spribe = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main mymenu-pages">
        <div className="PageHeading-container">
          <div className="PageHeading">
            <div className="back-link" onClick={() => navigate(-1)}>
              <img src={BackIcon} alt="back" />
            </div>
            <h6>Spribe</h6>
          </div>
        </div>
        <div className="casino-games">
          <ul className="games-list mt-4">
            {Array.isArray(Object.values(spribeData)) &&
              Object.values(spribeData).map((game, idx) => (
                <li key={game.code || idx} className="game-item">
                  <div className="img-box">
                    <img src={game.imgUrl} alt={game.name || ""} />

                    <div className="d-md-none">
                      <span className="name">{game.name}</span>
                    </div>
                    <div className="overlay">
                      <span className="name d-none d-md-block">
                        {game.name}
                      </span>
                      <a href={game.redirectUrl || "javascript:void(0)"}>
                        {" "}
                        <img src={PlayIcon} alt="PlayIcon" />
                      </a>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </>
  );
};

export default Spribe;
