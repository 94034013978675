import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import Facebook from "../assets/images/icons/foot/facebook.png";
import Instagram from "../assets/images/icons/foot/instagram.png";
import Twitter from "../assets/images/icons/foot/youtube.png";
import Telegram from "../assets/images/icons/foot/telegram.png";
import Whatsapp from "../assets/images/icons/foot/social.png";
import FooterImg1 from "../assets/images/icons/footerimg1.png";
import FooterImg2 from "../assets/images/icons/footerimg2.png";
import PromotionIcon from "../assets/images/icons/bot-menu/sports.png";
import DepositIcon from "../assets/images/icons/bot-menu/book.png";
import Exchange from "../assets/images/icons/bot-menu/exchange.webp";
import HomeMenu from "../assets/images/icons/bot-menu/home.png";
import CustomerIcon from "../assets/images/icons/bot-menu/customer.png";
import HomeIcon from "../assets/images/icons/bot-menu/home.png";
import MenuMenu from "../assets/images/icons/bot-menu/menu.png";
import ArrowRight from "../assets/images/icons/arrow-right.png";

import BankTransfer from "../assets/images/icons/foot/new/BankTransfer.png";
import Phonepe from "../assets/images/icons/foot/new/Phonepe.png";
import Bhim from "../assets/images/icons/foot/new/Bhim.png";
import UPI from "../assets/images/icons/foot/new/upi.png";
import Paytm from "../assets/images/icons/foot/new/Paytm.png";
import Skrill from "../assets/images/icons/foot/new/Skrill1.png";
import Netller from "../assets/images/icons/foot/new/Neteller.png";
import Superspade from "../assets/images/icons/foot/new/SuperSpade.png";
import AECasino from "../assets/images/icons/foot/new/AECasino.png";
import Evolution from "../assets/images/icons/foot/new/Evolution.png";
import Ezugi from "../assets/images/icons/foot/new/Ezugi.png";
import Habenero from "../assets/images/icons/foot/new/Habanero.png";
import Microgaming from "../assets/images/icons/foot/new/MicroGaming.png";
import Playtech from "../assets/images/icons/foot/new/Playtech.png";
import PragamticPlay from "../assets/images/icons/foot/new/Pragamatic.png";
import Saba from "../assets/images/icons/foot/new/SabaSports.png";
import SeaSports from "../assets/images/icons/foot/new/SoftSwis.png";
import Bbh from "../assets/images/icons/foot/new/BBIN.png";
import Bti from "../assets/images/icons/foot/new/BTI.png";
import Cq9 from "../assets/images/icons/foot/new/CQ9.png";

import Plus18 from "../assets/images/icons/foot/new/plus18.png";
import Mobilefriendly from "../assets/images/icons/foot/new/MobileFriendly.png";
import Lock from "../assets/images/icons/foot/new/Lock.png";
import Softswiss from "../assets/images/icons/foot/new/SoftSwis.png";
import GameCare from "../assets/images/icons/foot/new/GAmeCare.png";
import GA from "../assets/images/icons/foot/new/GA.png";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = ["betby", "exchange", "casino", "menu", "/"];
  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <div className="d-md-block d-none">
        <Container fluid>
          <div className="footerlogo">
            <img src={appDetails?.LOGO_URL} alt="Logo" />
          </div>
          <div className="footerList">
            <div className="footerText">
              <p>
                Winz11 will lead you through the fascinating journey of more
                than 4000 premium games and will reveal impressive rewards so
                you can relax and enjoy the gameplay. Along with playing all the
                games from the best game providers such as NetEnt, Yggdrasil,
                Play'n GO, Microgaming, EGT, NYX and others, you can rely on the
                secure usage of cutting-edge cryptocurrency payment solutions.
                The goal of the Winz11 team is to provide players with licensed
                and fair service, understand their needs, and bring the fun.{" "}
                <br /> Enjoy the gameplay and succeed with us!
              </p>
              <div className="footerImg">
                <ul>
                  <li>
                    <img src={FooterImg1} alt="" />
                  </li>
                  <li>
                    <img src={FooterImg2} alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="footerGame">
              <ul>
                <li>
                  <a href="/casino/ezugi">Live Casino</a>
                </li>
                <li>
                  <a href="/casino/evolution">Evolution</a>
                </li>
                <li>
                  <a href="/casino/vivo">Vivo</a>
                </li>
                <li>
                  <a href="/casino/qtech">Qtech</a>
                </li>
                <li>
                  <a href="/casino/supernowa">Supernowa</a>
                </li>
                <li>
                  <a href="/exchange/inplay">Exchange</a>
                </li>
                {/* <li>
                  <a href="/exchange/ESports">E Sports </a>
                </li> */}
                <li>
                  <a href="/Slots">Slots </a>
                </li>
                <li>
                  <a href="/aura-games">Aura Games </a>
                </li>
                <li>
                  <a href="/spribe">Spribe </a>
                </li>
              </ul>
            </div>
            <div className="footerGame">
              <ul>
                <li>
                  <a href="#">Affiliates</a>
                </li>
                <li>
                  <a href="#">Promotions</a>
                </li>
                <li>
                  <a href="/contactuss">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="footerGame">
              <ul>
                <li>
                  <a href="/termss">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/responsiblegames">Responsible Gaming</a>
                </li>
              </ul>
            </div>
            <div className="footerFollowus">
              <p>Follow US</p>
              <ul>
                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={Facebook} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "#"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? appDetails?.WHATS_APP_URL
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <img src={Twitter} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="pay-icon">
            <ul className="card-list">
              <li>
                <img src={BankTransfer} alt="" />
              </li>
              <li>
                <img src={Phonepe} alt="" />
              </li>
              <li>
                <img src={Bhim} alt="" />
              </li>
              <li>
                <img src={UPI} alt="" />
              </li>
              <li>
                <img src={Paytm} alt="" />
              </li>
              <li>
                <img src={Skrill} alt="" />
              </li>
              <li>
                <img src={Netller} alt="" />
              </li>
            </ul>
            <ul className="card-list provider-list">
              <li>
                <img src={Superspade} alt="" />
              </li>
              <li>
                <img src={AECasino} alt="" />
              </li>
              <li>
                <img src={Evolution} alt="" />
              </li>
              <li>
                <img src={Ezugi} alt="" />
              </li>
              <li>
                <img src={Habenero} alt="" />
              </li>
              <li>
                <img src={Microgaming} alt="" />
              </li>
              <li>
                <img src={Playtech} alt="" />
              </li>
              <li>
                <img src={PragamticPlay} alt="" />
              </li>
              <li>
                <img src={Saba} alt="" />
              </li>
              <li>
                <img src={SeaSports} alt="" />
              </li>
              <li>
                <img src={Bbh} alt="" />
              </li>
              <li>
                <img src={Bti} alt="" />
              </li>
              <li>
                <img src={Cq9} alt="" />
              </li>
            </ul>
          </div>
          <div className="end">
            <ul className="end-list">
              <li>
                <img src={Plus18} alt="" />
              </li>
              <li>
                <img src={GA} alt="" />
              </li>
              <li>
                <img src={GameCare} alt="" />
              </li>
              <li>
                <img src={Softswiss} alt="" />
              </li>
              <li>
                <img src={Mobilefriendly} alt="" />
              </li>
              <li>
                <img src={Lock} alt="" />
              </li>
            </ul>
          </div>
        </Container>
      </div>

      <div className="bottom-menu d-md-none">
        <ul>
          <li>
            <a href="/" className={activePage == "/" ? "active" : ""}>
              <img src={HomeIcon} />
              <span>Home</span>
            </a>
          </li>

          <li>
            <a
              href="/exchange/inplay"
              className={activePage == "/exchange/inplay" ? "active" : ""}
            >
              <img src={Exchange} />
              <span>Sports</span>
            </a>
          </li>
          <li>
            <a
              href="/gatewalylist"
              className={activePage == "/gatewalylist" ? "active" : ""}
            >
              <img src={DepositIcon} />
              <span>Deposit</span>
            </a>
          </li>

          <li>
            <a
              href="/contactuss"
              className={activePage == "contactus" ? "active" : ""}
            >
              <img src={CustomerIcon} />
              <span>Customer</span>
            </a>
          </li>
          <li>
            <a href="/menu" className={activePage == "menu" ? "active" : ""}>
              <img src={MenuMenu} />
              <span>Menu</span>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
