import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class GamesRule extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <div>
          <main className="main">
            <div className="container">
              <h2 class="sectionTitle"> Games Rules</h2>
              <p>
                The following Betting Rules pertaining to the bookmaker Winz11
                (here in after referred to as the Rules) stipulate the manner of
                accepting bets, paying winnings and resolving disputes, as well
                as the specific features of certain bets on certain sports.
                These Rules shall govern any other relations between the
                bookmaker Winz11 and the customer. These Rules shall apply to
                betting on the website and at Winz11 betting facilities.
              </p>
              <p>
                1. Bet is a risk-driven agreement for potential winnings entered
                into between the customer and the bookmaker under the
                established Rules, where the fulfillment of such agreement is
                conditioned by an event whose outcome is yet to be determined.
                Bets are accepted on the conditions offered by the bookmaker.
              </p>
              <p>
                2. Outcome is the result of the event (events) on which the bet
                was placed.
              </p>
              <p>
                3. Customer is an individual placing a bet with the bookmaker on
                an outcome.
              </p>
              <p>
                4. Bet Cancellation is an outcome on which the bet is not
                settled and winnings are not paid. As per the Rules, in the
                event of “bet cancellation”, an arrangement between the
                bookmaker and the customer shall be deemed unconcluded and the
                stake shall be refunded.
              </p>
              <p>
                5. Regular Time is the duration of the match subject to the
                regulations of the relevant sport, including time added by the
                referee. Regular time does not include extra time, overtime(s),
                penalty shootouts, etc.
              </p>

              <h3>Exchange Rules and Regulations</h3>
              <p>
                The Winz11 Exchange Rules and Regulations ("Exchange Rules") are
                part of Winz11's terms and conditions.
              </p>
              <p>
                The General Rules apply to all bets unless stated otherwise in
                the Market Information or the Specific Sports Rules. If there is
                any inconsistency between the Specific Sports Rules and the
                General Rules, the Specific Sports Rules shall prevail. If there
                is any inconsistency between the Market Information and either
                the General Rules or the Specific Sports Rules, the Market
                Information shall prevail, except where the General Rules or
                Specific Sports Rules use the phrase 'regardless of what it says
                in the Market Information' or similar wording.
              </p>
              <p>
                For any category or market not referred to in the Specific
                Sports Rules (e.g. 'Special Bets' or beach volleyball), the
                General Rules and Market Information will apply.
              </p>
              <p>
                Please note that there are separate Rules and Regulations for
                each of our Exchange and other products. The rules governing how
                markets are offered, managed and/or settled are not the same for
                every market on each product. In certain circumstances, a bet
                that is settled as a winner on one product may be settled as a
                loser on the other product (and vice versa). Additionally,
                different settlement rules may apply so that, for example, bets
                that are a winner on one product may be settled as a dead heat
                or be voided on the other product. Customers must ensure that
                they familiarize themselves with the relevant rules that apply
                to the bets that they place on Winz11's products.
              </p>

              <h3>General rules</h3>
              <p>
                10% comission is charged on total win (Profit) amount. This
                rules is applied for only Exchange Bets.
              </p>
              <p>
                For everything other than horseracing and greyhound racing, if a
                market is not scheduled to be turned in-play but Winz11 fails to
                suspend the market at the relevant time, then:
              </p>
              <p>
                If the event has a scheduled 'off' time, all bets matched after
                that scheduled off time will be void
              </p>
              <p>
                If the event does not have a scheduled 'off' time, FairExchange
                will use its reasonable endeavors to ascertain the time of the
                actual 'off' and all bets after the time of the 'off' determined
                by Winz11 will be void
              </p>
              <p>
                For horseracing and greyhound racing, if a market is not
                scheduled to be turned in-play but Winz11 fails to suspend the
                market at the relevant time, then all bets matched after the
                official 'off' time will be void.
              </p>
              <p>
                Winz11 aims to use its reasonable endeavors to suspend in-play
                markets at the start of and at the end of the event. However,
                regardless of what it says in the Market Information, Winz11
                does not guarantee that such markets will be suspended at the
                relevant time.
              </p>
              <p>
                Winz11 will not part-suspend outcomes/selections in an Exchange
                market that has been turned in-play.
              </p>
              <p>
                All customers are responsible for managing their in-play bets at
                all times.
              </p>
              <p>
                For the purposes of in-play betting, customers should be aware
                that transmissions described as "live" by some broadcasters may
                actually be delayed or pre-recorded. The extent of any delay may
                vary depending on the set-up through which they are receiving
                pictures or data.
              </p>
              <p>
                The availability of an in-play market on our Exchange product
                for a particular event does not necessarily mean that there will
                be an equivalent in-play market for the same event on our other
                products.
              </p>
              <p>
                All bets placed in the system are the clients responsibility.
                Any miss bets due to software glitch or website maintenance will
                not be Winz11 responsibility and clients have to bear any or
                such losses incurred during this downtime.
              </p>
              <p>
                All markets other than soccer markets - not suspending at the
                time of the 'off'
              </p>
              <p>
                In relation to markets which are scheduled to be turned in-play,
                Winz11 aims to use its reasonable endeavors to turn such markets
                in-play at the time of the 'off'. However, Winz11 does not
                guarantee that such markets will be suspended and turned in-play
                at the time of the 'off'.
              </p>
              <p>
                If a market is scheduled to be turned in-play but Winz11 fails
                to suspend the market at the time of the 'off' and the market is
                not turned in-play at any time during the event, all bets
                matched after the scheduled time of the 'off' will be void (in
                the case of horseracing and greyhound racing, bets will be void
                from the official rather than the scheduled 'off' time) UNLESS
                it can be established that the bet was placed before the
                official off time or the actual time of the off. If the event
                does not have a scheduled 'off' time, Winz11 will use its
                reasonable endeavors to ascertain the time of the actual 'off'
                and all bets after the time of the 'off' determined by Winz11
                will be void.
              </p>
              <p>
                If a market is scheduled to be turned in-play but Winz11 fails
                to suspend the market at the time of the 'off', but the market
                is turned in-play at a later time during the event, all bets
                matched after the time of the 'off' will stand HOWEVER the price
                may be adjusted by Winz11 and the bets may be settled
                accordingly to Winz11 prices. Change of venue
              </p>
              <p>
                Some markets have different rules and these are listed in the
                Specific Sports Rules. However, if change of venue is not dealt
                with in the Specific Sports Rules then the following shall
                apply:
              </p>
              <p>
                For any team sport: if the scheduled venue is changed after a
                bet is placed, all bets will be void only if the new venue is a
                home ground of the original away team.
              </p>
              <p>
                For all categories or markets other than team sports: if the
                scheduled venue is changed after a bet is placed, all bets will
                stand.
              </p>
              <p>
                If there is a change in the type of scheduled surface (e.g. a
                hockey match switching from grass to astro-turf) after a bet is
                placed, all bets will stand.
              </p>
              <p>Abandonments, Cancellations, Postponements</p>
              <p>
                Some markets have different rules and these are listed in the
                Specific Sports Rules. However, where a market has no rules in
                the Specific Sports Rules in relation to abandonment,
                cancellation and/or postponement the following shall apply.
              </p>
              <p>
                In relation to any match, fixture, game, individual event, race
                or similar: If the event is not completed within three days
                after the scheduled completion date, then all bets on markets
                for this event will be void, except for bets on any markets that
                have been unconditionally determined.
              </p>
              <p>
                In relation to any tournament, competition or similar: If the
                event is not completed within three days after the scheduled
                completion date of the event, then any markets relating to the
                event will be settled in accordance with the official ruling of
                the relevant governing body, providing such a decision is given
                within 90 days after the scheduled completion date. If no
                official ruling is announced in this 90 day period, then bets on
                any market relating to this event will be void, except for bets
                on any markets which have been unconditionally determined. If a
                market is to be voided but has been part-settled as a courtesy
                to Winz11 customers, then such part-settled bets will be
                reversed and all bets on the market will be void.
              </p>
              <p>
                If there is no further sporting action in any market that does
                not contain the selection 'draw' or 'tie', all bets placed after
                this conclusion of action will be voided.
              </p>
              <p>
                Winz11 will decide (acting reasonably) whether a market relates
                to a match (or similar) or a tournament (or similar). Results
                and market settlement
              </p>

              <h3>General</h3>
              <p>
                Where the Specific Sports Rules do not specify how and on what
                basis a market will be settled, markets will be settled on the
                official result of the relevant governing body regardless of any
                subsequent disqualification or amendment to the result (except
                if an amendment is announced within 24 hours of the initial
                settlement of the relevant market in order to correct an error
                in reporting the result).
              </p>
              <p>
                If no official result of a relevant governing body is available,
                the result will be determined by Winz11 (acting reasonably)
                using information from independent sources. In such cases, if
                any new information comes into the public domain within 48 hours
                of settlement, then Winz11 shall (acting reasonably) determine
                either:
              </p>
              <p>
                Whether the market should be reinstated or resettled in light of
                this new information
              </p>
              <p>
                Whether or not to wait for further information before deciding
                whether to reinstate or resettle the market. Except where Winz11
                has announced that it is waiting for further information, any
                information that comes into the public domain more than 48 hours
                after a market has been settled shall not be considered by
                Winz11 (regardless of whether or not such information may have
                led to a different result).
              </p>
              <p>
                In the event of any uncertainty about any result or potential
                result, Winz11 reserves the right to suspend settlement of any
                market for an unlimited period until the uncertainty can be
                resolved to the reasonable satisfaction of Winz11 . Winz11
                reserves the right to void any market if the uncertainty
                regarding settlement cannot be resolved to Winz11 's reasonable
                satisfaction.
              </p>

              <h3>Resettlements</h3>
              <p>
                Markets are generally settled shortly after the end of the event
                in question. Winz11 may settle (or part-settle) some markets
                before the official result is declared (or may increase a
                customer's 'available to bet' balance by the minimum potential
                winnings of that customer on a given market) purely as a
                customer service benefit. However, Winz11 reserves the right to
                amend the settlement of the market if:
              </p>
              <p>
                The official result is different to the result on which Winz11
                initially settled the market (for example, a horseracing result
                being changed by the relevant governing body shortly after a
                race)
              </p>
              <p>
                If the whole market is eventually voided (e.g. for an abandoned
                event).
              </p>
              <p>
                Winz11 reserves the right to reverse the settlement of a market
                if a market is settled in error (for example, a human or
                technical error).
              </p>
              <p>
                If Winz11 resettles a market, this may lead to amendments being
                made to a customer's balance to reflect changes in market
                settlement.
              </p>

              <h3>Non Runners</h3>
              <p>
                Unless stated otherwise in respect of any particular market, all
                bets are 'all in compete or not'. This means that if an
                individual or team is withdrawn or disqualified before they have
                taken part in the event bets on the selection will be deemed
                losers. However if the event specifically says it is 'non-runner
                no bet' stakes will be refunded if there is a withdrawal prior
                to the event. In the case of withdrawals we may apply a
                'Tattersalls rule 4 deduction' to any winnings based upon the
                price of the withdrawn selection(s).
              </p>

              <h3>Specific Sports Rules</h3>
              <p>
                The specific Sports Rules will follow all rules as specify in
                Winz11 website.
              </p>

              <h3>IRREGULAR BETS</h3>
              <p>
                The Operator reserves the right to refuse, reject or suspend
                without prior notification any End User who is suspected of
                cheating, hacking, attacking, manipulating or damaging the
                Operator's normal betting operations (including the Website).
                Any of the "abnormal bets" will be void without prior
                notification. The use of artificial intelligence or "bots" on
                the Website is strictly forbidden. The Operator will take
                measures to prevent and detect programmes that are used to
                enable artificial intelligence (non human) to utilise the
                Website. Any attempted or actual use of artificial intelligence
                by the End User to cheat, hack, attack, manipulating or damage
                the Operator's betting operations will lead to termination of
                their account and/or voiding the bets, at the discretion of the
                Operator.
              </p>
            </div>
          </main>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default GamesRule;
