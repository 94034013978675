import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Table } from "react-bootstrap";
import { IoEye } from "react-icons/io5";
import { API_URLS } from "../../../../config/apiUrls";
import { useSWRFetcher } from "../../../../hooks/useSWRHooks";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BackIcon from "../../../../assets/images/icons/arrow-back.png";
import moment from "moment/moment";

const Bonus = () => {
  const navigate = useNavigate();

  const { data } = useSWRFetcher({
    url: API_URLS.BONUS.CLAIMS,
    isExternalFetch: true,
  });
  // ?skip=${0}&pagesize=${10}&startdate=&enddate=&sort[date]=asc

  return (
    <>
      <HeaderAfterLogin />

      <main className="main mymenu-pages">
        <div className="PageHeading-container">
          <div className="PageHeading">
            <div className="back-link" onClick={() => navigate(-1)}>
              <img src={BackIcon} alt="back" />
            </div>
            <h6>Bonus</h6>
          </div>
        </div>

        <div className="inner-content">
          <div className="new_table mt-4">
            <Table className="table bonus_table" responsive>
              <thead className="">
                {/* <th width="">Name</th> */}
                <th width="">Code</th>
                <th>Bonus Type</th>
                <th width="">Bonus Amount</th>
                <th width="">Rollover amount </th>
                {/* <th>Parts</th> */}
                <th width="">Created date</th>
                <th width="">View </th>
              </thead>
              <tbody>
                {Array.isArray(data?.statement) &&
                  data?.statement.map((item, index) => (
                    <tr key={index}>
                      {/* <td>{item.bonusName}</td> */}
                      <td>{item.bonusCode}</td>
                      <td>{item.bonusType}</td>
                      <td>
                        {item.creditedAmount} / {item.creditableAmount}
                      </td>
                      <td>
                        {item.claimedAmount} / {item.goalAmount}
                      </td>
                      {/* <td>
                      {item.claimedParts} / {item.totalParts}
                    </td> */}
                      <td> {moment(item.createdAt).format("DD-MM-YY h:mm")}</td>

                      <td>
                        {" "}
                        <Link to={`/bonus/${item.id}`}>
                          <IoEye className="eye_" />
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </main>

      <div className="d-md-none">
        <Footer />
      </div>
    </>
  );
};

export default Bonus;
