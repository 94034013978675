export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const slotsGames = {
  netenttab: {
    asgardianstones: {
      redirectUrl: "/casino/ezgne-asgardian-stones",
      code: "1100096",
      casino: "ezugi",
      imgUrl: "./images/slots/asguardianstones.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Asgardian Stones",
      providerName: "NetEnt",
    },
    secrets: {
      redirectUrl: "/casino/ezgne-secrets-of-atlantis",
      code: "1100106",
      casino: "ezugi",
      imgUrl: "./images/slots/seceretofatlantis.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Secrets of Atlantis",
      providerName: "NetEnt",
    },
    SteamTower: {
      redirectUrl: "/casino/ezgne-steam-tower",
      code: "1100043",
      casino: "ezugi",
      imgUrl: "./images/slots/steamtower.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Steam Tower",
      providerName: "NetEnt",
    },
    DivinieFortuneMegaways: {
      redirectUrl: "/casino/ezgne-divine-fortune-megaways",
      code: "1100089",
      casino: "ezugi",
      imgUrl: "./images/slots/divinefortunemegaways.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Divine Fortune Megaways",
      providerName: "NetEnt",
    },
    FruitShopMegaways: {
      redirectUrl: "/casino/ezgne-fruit-shop-megaways",
      code: "1100094",
      casino: "ezugi",
      imgUrl: "./images/slots/FruiShopMegaways.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Fruit Shop Megaways",
      providerName: "NetEnt",
    },
    GorillaKingdom: {
      redirectUrl: "/casino/ezgne-gorilla-kingdom",
      code: "1100088",
      casino: "ezugi",
      imgUrl: "./images/slots/gorillakingdom.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Gorilla Kingdom",
      providerName: "NetEnt",
    },
    // ReelKingMegaways: {
    //   redirectUrl: "/casino/ezgrt-reel-king-mega",
    //   code: "1100052",
    //   casino: "ezugi",
    //   imgUrl: "./images/slots/Reel_King_Mega.jpg",
    //   homeUrl: pageUrlGenerator("/Slots"),
    //   name: "Reel King Mega",
    //   providerName: "NetEnt",
    // },
    // Christmas: {
    //   redirectUrl: "/casino/ezgne-aloha-christmas",
    //   code: "1100032",
    //   casino: "ezugi",
    //   imgUrl: "./images/slots/Aloha-Christmas.jpg",
    //   homeUrl: pageUrlGenerator("/Slots"),
    //   name: "Aloha! Christmas",
    //   providerName: "NetEnt",
    // },
    ClusterPays: {
      redirectUrl: "/casino/ezgne-aloha-cluster-pays",
      code: "1100033",
      casino: "ezugi",
      imgUrl: "./images/slots/AlohaClusterPays.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Aloha! Cluster Pays",
      providerName: "NetEnt",
    },
    WildWest: {
      redirectUrl: "/casino/ezgne-wild-wild-west-the-great-train-heist",
      code: "1100038",
      casino: "ezugi",
      imgUrl: "./images/slots/WildWildWest.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Wild Wild West: The Great Train Heist",
      providerName: "NetEnt",
    },
    TwinSpinMegaways: {
      redirectUrl: "/casino/ezgne-twin-spin-megaways",
      code: "1100039",
      casino: "ezugi",
      imgUrl: "./images/slots/TwinSpinMegaways.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Twin Spin Megaways",
      providerName: "NetEnt",
    },
    TurnFortune: {
      redirectUrl: "/casino/ezgne-turn-your-fortune",
      code: "1100041",
      casino: "ezugi",
      imgUrl: "./images/slots/Turnyourfortune.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Turn Your Fortune",
      providerName: "NetEnt",
    },
    Pyramid: {
      redirectUrl: "/casino/ezgne-pyramid-quest-for-immortality",
      code: "1100078",
      casino: "ezugi",
      imgUrl: "./images/slots/pyramidequestofimmortality.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Pyramid: Quest for Immortality",
      providerName: "NetEnt",
    },
    PiggyRiches: {
      redirectUrl: "/casino/ezgne-piggy-riches",
      code: "1100080",
      casino: "ezugi",
      imgUrl: "./images/slots/PiggyRiches.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Piggy Riches",
    },
    DarkKing: {
      redirectUrl: "/casino/ezgne-dark-king-forbidden-riches",
      code: "1100081",
      casino: "ezugi",
      imgUrl: "./images/slots/darkkingforbiddenriches.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dark King: Forbidden Riches",
      providerName: "NetEnt",
    },
    JingleSpin: {
      redirectUrl: "/casino/ezgne-jingle-spin",
      code: "1100082",
      casino: "ezugi",
      imgUrl: "./images/slots/JingleSpin.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jingle Spin",
      providerName: "NetEnt",
    },
    JackHammer: {
      redirectUrl: "/casino/ezgne-jack-hammer",
      code: "1100084",
      casino: "ezugi",
      imgUrl: "./images/slots/JackHammer.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jack Hammer",
      providerName: "NetEnt",
    },
    DeadorAlive: {
      redirectUrl: "/casino/ezgne-dead-or-alive",
      code: "1100085",
      casino: "ezugi",
      imgUrl: "./images/slots/deadalive.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dead or Alive",
      providerName: "NetEnt",
    },
    // DeadorAlive2: {
    //   redirectUrl: "/casino/ezgne-dead-or-alive-2",
    //   code: "1100087",
    //   casino: "ezugi",
    //   imgUrl: "./images/slots/Dead-or-Alive-2.jpg",
    //   homeUrl: pageUrlGenerator("/Slots"),
    //   name: "Dead or Alive 2",
    //   providerName: "NetEnt",
    // },
    JackandtheBeanstalk: {
      redirectUrl: "/casino/ezgne-jack-and-the-beanstalk",
      code: "1100086",
      casino: "ezugi",
      imgUrl: "./images/slots/JackandtheBeanstalk.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jack and the Beanstalk",
      providerName: "NetEnt",
    },
    // Elements: {
    //   redirectUrl: "/casino/ezgne-elements-the-awakening",
    //   code: "1100091",
    //   casino: "ezugi",
    //   imgUrl: "./images/slots/Elements-The-Awakening.jpg",
    //   homeUrl: pageUrlGenerator("/Slots"),
    //   name: "Elements: The Awakening",
    //   providerName: "NetEnt",
    // },
    FinnandtheSwirlySpin: {
      redirectUrl: "/casino/ezgne-finn-and-the-swirly-spin",
      code: "1100093",
      casino: "ezugi",
      imgUrl: "./images/slots/finnandthweswearl.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Finn and the Swirly Spin",
      providerName: "NetEnt",
    },
    // FruitShop: {
    //   redirectUrl: "/casino/ezgne-fruit-shop",
    //   code: "1100095",
    //   casino: "ezugi",
    //   imgUrl: "./images/slots/Fruit-Shop.jpg",
    //   homeUrl: pageUrlGenerator("/Slots"),
    //   name: "Fruit Shop",
    //   providerName: "NetEnt",
    // },
    DeadorAlive2FeatureBuy: {
      redirectUrl: "/casino/ezgne-dead-or-alive-2-feature-buy",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/DeadorAlive2FeatureBuy.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dead or Alive 2 Feature Buy",
      providerName: "NetEnt",
    },
    JackHammer2FishyBusiness: {
      redirectUrl: "/casino/ezgne-jack-hammer-2-fishy-business",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/JackHammer2FishyBusiness.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jack Hammer 2 Fishy Business",
      providerName: "NetEnt",
    },
    BloodSuckers: {
      redirectUrl: "/casino/ezgne-blood-suckers",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/BloodSuckers.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Blood Suckers",
      providerName: "NetEnt",
    },
    ButterflyStaxx: {
      redirectUrl: "/casino/ezgne-butterfly-staxx",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/ButterflyStaxx.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Butterfly Staxx",
      providerName: "NetEnt",
    },
    ButterflyStaxx2: {
      redirectUrl: "/casino/ezgne-butterfly-staxx-2",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/ButterflyStaxx2.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Butterfly Staxx 2",
      providerName: "NetEnt",
    },
    dazzalme: {
      redirectUrl: "/casino/ezgne-dazzle-me",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/dazzalme.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dazzl me",
      providerName: "NetEnt",
    },
    DriveMultiplierMayhem: {
      redirectUrl: "/casino/ezgne-drive-multiplier-mayhem",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/DriveMultiplierMayhem.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Drive Multiplier Mayhem",
      providerName: "NetEnt",
    },
    FairytaleLegends: {
      redirectUrl: "/casino/ezgne-fairytale-legends-red-riding-hood",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/FairytaleLegends.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Fairytale Legends",
      providerName: "NetEnt",
    },
    FINNGOLDENTAVERN: {
      redirectUrl: "/casino/ezgne-finns-golden-tavern",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/FINNGOLDENTAVERN.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "FINN GOLDEN TAVERN",
      providerName: "NetEnt",
    },
    Flowers: {
      redirectUrl: "/casino/ezgne-flowers",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/Flowers.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Flowers",
      providerName: "NetEnt",
    },
    FruitShopChristmasEdition: {
      redirectUrl: "/casino/ezgne-fruit-shop-christmas-edition",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/FruitShopChristmasEdition.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "FruitShop Christmas Edition",
      providerName: "NetEnt",
    },
    FruitSpin: {
      redirectUrl: "/casino/ezgne-fruit-spin",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/FruitSpin.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Fruit Spin",
      providerName: "NetEnt",
    },
    Hotline: {
      redirectUrl: "/casino/ezgne-hotline",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/hotline1.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Hotline",
      providerName: "NetEnt",
    },
    hotline2: {
      redirectUrl: "/casino/ezgne-hotline-2",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/hotline_2.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Hotline 2",
      providerName: "NetEnt",
    },
    IrishPotLuck: {
      redirectUrl: "/casino/ezgne-irish-pot-luck",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/IrishPotLuck.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Irish Pot Luck",
      providerName: "NetEnt",
    },
    JackandtheBeanstalk: {
      redirectUrl: "/casino/ezgne-jack-and-the-beanstalk",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/JackandtheBeanstalk.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jack and the Beanstalk",
      providerName: "NetEnt",
    },
    JackHammer2FishyBusiness: {
      redirectUrl: "/casino/ezgne-jack-hammer-2-fishy-business",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/JackHammer2FishyBusiness.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Jack Hammer 2 Fishy Business",
      providerName: "NetEnt",
    },
    // Jimmyhendricks: {
    //   redirectUrl: "/casino/ezgne-jack-hammer-2-fishy-business",
    //   code: "1100100",
    //   casino: "ezugi",
    //   imgUrl: "./images/slots/Jimmyhendricks.png",
    //   homeUrl: pageUrlGenerator("/Slots"),
    //   name: "Jimmy hendricks",
    //   providerName: "NetEnt",
    // },
    junglespiritcallofthwewild: {
      redirectUrl: "/casino/ezgne-jungle-spirit-call-of-the-wild",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/junglespiritcallofthwewild.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "jungle spirit call of the wild",
      providerName: "NetEnt",
    },
    Koiprinces: {
      redirectUrl: "/casino/ezgne-koi-princess",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/Koiprinces.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Koi princes",
      providerName: "NetEnt",
    },
    ReelRush: {
      redirectUrl: "/casino/ezgne-reel-rush",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/MotorheadVideoSlot.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Reel Rush",
      providerName: "NetEnt",
    },
    SecretoftheStones: {
      redirectUrl: "/casino/ezgne-secret-of-the-stones",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/SecretoftheStones.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Secret of the Stones",
      providerName: "NetEnt",
    },
    Romegoldenage: {
      redirectUrl: "/casino/ezgne-rome-the-golden-age",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/Romegoldenage.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Rome golden age",
      providerName: "NetEnt",
    },
    SecretsofChristmas: {
      redirectUrl: "/casino/ezgne-secrets-of-christmas",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/SecretsofChristmas.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Secrets of Christmas",
      providerName: "NetEnt",
    },
    strengttikings: {
      redirectUrl: "/casino/ezgne-serengeti-kings",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/strengttikings.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Serengeti kings",
      providerName: "NetEnt",
    },
    victorious: {
      redirectUrl: "/casino/ezgne-victorious",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/victorious.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Victorious",
      providerName: "NetEnt",
    },
    WarlordsCrystalofPower: {
      redirectUrl: "/casino/ezgne-warlords-crystal-of-power",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/WarlordsCrystalofPower.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Warlords Crystal of Power",
      providerName: "NetEnt",
    },
    WildWater: {
      redirectUrl: "/casino/ezgne-wild-water",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/WildWater.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Wild Water",
      providerName: "NetEnt",
    },
    wildworlds: {
      redirectUrl: "/casino/ezgne-wild-worlds",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/wildworlds.png",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Wild worlds",
      providerName: "NetEnt",
    },
    WillysHotChillies: {
      redirectUrl: "/casino/ezgne-willys-hot-chillies",
      code: "1100100",
      casino: "ezugi",
      imgUrl: "./images/slots/WillysHotChillies.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Willys Hot Chillies",
      providerName: "NetEnt",
    },
    name: "Netent",
    key: "netent",
  },

  redtigertab: {
    NinjaWays: {
      redirectUrl: "/casino/ezgrt-ninja-ways",
      code: "1100166",
      casino: "ezugi",
      imgUrl: "./images/slots/Ninja-Ways.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Ninja Ways",
      providerName: "Red Tiger",
    },
    PathofDestiny: {
      redirectUrl: "/casino/ezgrt-path-of-destiny",
      code: "1100167",
      casino: "ezugi",
      imgUrl: "./images/slots/Path-of-Destiny.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Path of Destiny",
      providerName: "Red Tiger",
    },
    PersianFortune: {
      redirectUrl: "/casino/ezgrt-persian-fortune",
      code: "1100168",
      casino: "ezugi",
      imgUrl: "./images/slots/Persian-Fortune.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Persian Fortune",
      providerName: "Red Tiger",
    },
    ReelHeist: {
      redirectUrl: "/casino/ezgrt-reel-heist",
      code: "1100169",
      casino: "ezugi",
      imgUrl: "./images/slots/Reel-Heist.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Reel Heist",
      providerName: "Red Tiger",
    },
    RobinHoodWildForest: {
      redirectUrl: "/casino/ezgrt-robin-hoods-wild-forest",
      code: "1100170",
      casino: "ezugi",
      imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Robin Hood`s Wild Forest",
      providerName: "Red Tiger",
    },
    SnowWild: {
      redirectUrl: "/casino/ezgrt-snow-wild-and-the-7-features",
      code: "1100171",
      casino: "ezugi",
      imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Snow Wild And The 7 Features",
      providerName: "Red Tiger",
    },
    SylvanSpirits: {
      redirectUrl: "/casino/ezgrt-sylvan-spirits",
      code: "1100172",
      casino: "ezugi",
      imgUrl: "./images/slots/Sylvan-Spirits.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Sylvan Spirits",
      providerName: "Red Tiger",
    },
    TheGreatestTrainRobbery: {
      redirectUrl: "/casino/ezgrt-the-greatest-train-robbery",
      code: "1100173",
      casino: "ezugi",
      imgUrl: "./images/slots/The-Greatest-Train-Robbery.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "The Greatest Train Robbery",
      providerName: "Red Tiger",
    },
    TheWildHatter: {
      redirectUrl: "/casino/ezgrt-the-wild-hatter",
      code: "1100174",
      casino: "ezugi",
      imgUrl: "./images/slots/The-Wild-Hatter.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "The Wild Hatter",
      providerName: "Red Tiger",
    },
    ThorLightning: {
      redirectUrl: "/casino/ezgrt-thors-lightning",
      code: "1100175",
      casino: "ezugi",
      imgUrl: "./images/slots/Thor`s-Lightning.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Thor`s Lightning",
      providerName: "Red Tiger",
    },
    DragonLuck: {
      redirectUrl: "/casino/ezgrt-dragons-luck",
      code: "1100001",
      casino: "ezugi",
      imgUrl: "./images/slots/Dragon's-Luck.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dragon's Luck",
      providerName: "Red Tiger",
    },
    DragonLuckReel: {
      redirectUrl: "/casino/ezgrt-dragons-luck-power-reels",
      code: "1100002",
      casino: "ezugi",
      imgUrl: "./images/slots/Dragon's-Luck-Power-Reels.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dragon's Luck Power Reels",
      providerName: "Red Tiger",
    },
    TikiFruits: {
      redirectUrl: "/casino/ezgrt-tiki-fruits",
      code: "1100176",
      casino: "ezugi",
      imgUrl: "./images/slots/Tiki-Fruits.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Tiki Fruits",
      providerName: "Red Tiger",
    },
    TotemLightning: {
      redirectUrl: "/casino/ezgrt-totem-lightning",
      code: "1100177",
      casino: "ezugi",
      imgUrl: "./images/slots/Totem-Lightning.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Totem Lightning",
      providerName: "Red Tiger",
    },
    VaultCracker: {
      redirectUrl: "/casino/ezgrt-vault-cracker",
      code: "1100178",
      casino: "ezugi",
      imgUrl: "./images/slots/Vault-Cracker.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Vault Cracker",
      providerName: "Red Tiger",
    },
    VaultofAnubis: {
      redirectUrl: "/casino/ezgrt-vault-of-anubis",
      code: "1100179",
      casino: "ezugi",
      imgUrl: "./images/slots/Vault-of-Anubis.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Vault of Anubis",
      providerName: "Red Tiger",
    },
    WellofWishes: {
      redirectUrl: "/casino/ezgrt-well-of-wishes",
      code: "1100180",
      casino: "ezugi",
      imgUrl: "./images/slots/Well-of-Wishes.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Well of Wishes",
      providerName: "Red Tiger",
    },
    WildCatsMultiline: {
      redirectUrl: "/casino/ezgrt-wild-cats-multiline",
      code: "1100181",
      casino: "ezugi",
      imgUrl: "./images/slots/Wild-Cats-Multiline.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Wild Cats Multiline",
      providerName: "Red Tiger",
    },
    WildOClock: {
      redirectUrl: "/casino/ezgrt-wild-oclock",
      code: "1100182",
      casino: "ezugi",
      imgUrl: "./images/slots/Wild-O`Clock.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Wild O`Clock",
      providerName: "Red Tiger",
    },
    WildChest: {
      redirectUrl: "/casino/ezgrt-wild-wild-chest",
      code: "1100183",
      casino: "ezugi",
      imgUrl: "./images/slots/Wild-Wild-Chest.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Wild Wild Chest",
      providerName: "Red Tiger",
    },
    WinEscalator: {
      redirectUrl: "/casino/ezgrt-win-escalator",
      code: "1100184",
      casino: "ezugi",
      imgUrl: "./images/slots/Win-Escalator.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Win Escalator",
      providerName: "Red Tiger",
    },
    YucatanMystery: {
      redirectUrl: "/casino/ezgrt-yucatans-mystery",
      code: "1100185",
      casino: "ezugi",
      imgUrl: "./images/slots/Yucatan`s-Mystery.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Yucatan`s Mystery",
      providerName: "Red Tiger",
    },
    DragonFire: {
      redirectUrl: "/casino/ezgrt-dragons-fire",
      code: "1100008",
      casino: "ezugi",
      imgUrl: "./images/slots/Dragon's-Fire.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dragon's Fire",
      providerName: "Red Tiger",
    },
    DragonLuckDeluxe: {
      redirectUrl: "/casino/ezgrt-dragons-luck-power-reels",
      code: "1100011",
      casino: "ezugi",
      imgUrl: "./images/slots/Dragon's-Luck-Deluxe.jpg",
      homeUrl: pageUrlGenerator("/Slots"),
      name: "Dragon's Luck Deluxe",
      providerName: "Red Tiger",
    },
    name: "Red Tiger",
    key: "redTiger",
  },
};
