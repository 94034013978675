import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../../../redux/auth/actions";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import ArrowRight from "../../../../assets/images/icons/arrow-right.png";
import ArrowBack from "../../../../assets/images/icons/arrow-back.png";
import Logout from "../../../../assets/images/icons/menu/logout.png";

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="my-account">
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="account-page">
          <div className="heading-title">
            <a href="javascript:void(0)" onClick={() => navigate(-1)}>
              <img src={ArrowBack} alt="back" />
            </a>
            <span>Profile</span>
          </div>

          <div className="account-content">
            <ul>
              <li className="phone">{user?.mobileno}</li>
            </ul>

            <ul>
              <li className="title">Account Profile</li>
              <li>
                <a href="/profile">
                  Personal Details
                  <img src={ArrowRight} alt="arrow right" />
                </a>
              </li>
            </ul>

            <ul>
              {/* <li className="title">Deposits and Payouts</li> */}
              <li className="title">Payouts</li>
              {/* <li>
                <a href="/gatewaylist">
                  Deposit
                  <img src={ArrowRight} alt="arrow right" />
                </a>
              </li> */}
              <li>
                <a href="/withdraw">
                  Withdrawal
                  <img src={ArrowRight} alt="arrow right" />
                </a>
              </li>
            </ul>

            <ul>
              <li className="title">History</li>
              <li>
                <a href="/mybets">
                  Bet History
                  <img src={ArrowRight} alt="arrow right" />
                </a>
              </li>
              <li>
                <a href="/cashier">
                  Transaction History
                  <img src={ArrowRight} alt="arrow right" />
                </a>
              </li>
            </ul>

            <ul>
              <li className="title">Bonuses</li>
              <li>
                <a href="/bonus">
                  Bonuses
                  <img src={ArrowRight} alt="arrow right" />
                </a>
              </li>
              <li>
                <a href="/referral">
                  Referral
                  <img src={ArrowRight} alt="arrow right" />
                </a>
              </li>
            </ul>

            <ul>
              <li>
                <a
                  href="/"
                  onClick={() => {
                    dispatch(logoutUser());
                  }}
                >
                  Logout
                  <img src={Logout} alt="logout" className="logout" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </div>
  );
};

export default Account;
