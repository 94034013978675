import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import MainSlider from "../../../../containers/MainSlider";
import { slotsGames } from "../../../../lib/data/slots.data";
import { spribeData } from "../../../../lib/data/spripe.data";
import { betgamesData } from "../../../../lib/data/betgames.data";
import { KingmakerGamesData } from "../../../../lib/data/kingmakernewgamesdata";

const Casino = () => {
  console.log("spribeData", spribeData);
  const { isAuth } = useSelector((state) => state.auth);
  const { banners } = useSelector((state) => state.app);
  const categoriesTab = [
    // { name: "Home", key: "home" },
    { name: "Netent", key: "netenttab" },
    { name: "RedTiger", key: "redtigertab" },
    { name: "Spribe", key: "spribe", isSpribe: true },
    { name: "betgames", key: "betgames", isbetgames: true },
    { name: "Kingmaker", key: "kingmaker", iskingmaker: true },
  ];
  const [activeCategory, setActiveCategory] = useState("netenttab");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(getActiveCategoryGames("netenttab"));
  }, []);

  const getActiveCategoryGames = (category) => {
    if (category === "spribe") {
      return Object.values(spribeData || {});
    }
    if (category === "betgames") {
      return Object.values(betgamesData || {});
    }
    if (category === "kingmaker") {
      return Object.values(KingmakerGamesData || {});
    }
    return Object.values(slotsGames[category] || {}).filter(
      (game) => typeof game === "object" && game.name
    );
  };

  const settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const isMobile = window.screen.width > 776 ? false : true;

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="top_banner_sec">
          <div className="mainBanner">
            <MainSlider banners={banners} />
          </div>
        </div>

        <div className="casino-games">
          <div className="game-filter ">
            <ul className="categories">
              {categoriesTab
                ?.filter((item) => (isMobile ? item : item?.name !== "Home"))
                .map((tab, index) => (
                  <li key={index}>
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        setActiveCategory(tab.key);
                        setFilteredData(getActiveCategoryGames(tab.key));
                      }}
                      className={activeCategory === tab.key ? "active" : ""}
                    >
                      <span>{tab.name}</span>
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          {filteredData.length > 0 ? (
            <ul className="games-list">
              {filteredData.map((game, idx) => (
                <li key={idx} className="game-item">
                  <div className="img-box">
                    <img src={game.imgUrl} alt={game.name || ""} />

                    <div className="overlay">
                      <span className="name d-none d-md-block">
                        {game.name}
                      </span>
                      <a href={game.redirectUrl || "javascript:void(0)"}>
                        Play
                      </a>
                    </div>
                  </div>
                  <div className="d-md-none">
                    <span className="name">{game.name}</span>
                  </div>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </>
  );
};

export default Casino;
