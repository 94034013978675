import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { slotsGames } from "../../../../lib/data/slots.data";

import PlayIcon from "../../../../assets/images/icons/play.png";

const Slots = () => {
  const categoriesTab = [
    { name: "Netent", key: "netenttab" },
    { name: "RedTiger", key: "redtigertab" },
  ];
  const [activeCategory, setActiveCategory] = useState("netenttab");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredData(getActiveCategoryGames("netenttab"));
  }, []);

  const getActiveCategoryGames = (category) => {
    return Object.values(slotsGames[category] || {}).filter(
      (game) => typeof game === "object" && game.name
    );
  };

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="casino-games">
          <div className="game-filter">
            <ul className="categories">
              {categoriesTab.map((tab, index) => (
                <li key={index}>
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      setActiveCategory(tab.key);
                      setFilteredData(getActiveCategoryGames(tab.key));
                    }}
                    className={activeCategory === tab.key ? "active" : ""}
                  >
                    <span>{tab.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <ul className="games-list">
            {filteredData.length > 0 ? (
              filteredData.map((game, idx) => (
                <li key={game.code || idx} className="game-item">
                  <div className="img-box">
                    <img src={game.imgUrl} alt={game.name || ""} />

                    <div className="d-md-none">
                      <span className="name">{game.name}</span>
                    </div>
                    <div className="overlay">
                      <span className="name d-none d-md-block">
                        {game.name}
                      </span>
                      <a href={game.redirectUrl || "javascript:void(0)"}>
                        {" "}
                        <img src={PlayIcon} alt="PlayIcon" />
                      </a>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <li>No games available</li>
            )}
          </ul>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Slots;
