import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import Mybets from "../../../../assets/images/icons/menu/bets.png";
import Referral from "../../../../assets/images/icons/menu/referral.svg";
import Bonus from "../../../../assets/images/icons/menu/bonus.png";
import LiveCasino from "../../../../assets/images/icons/menu-page/live-casino.svg";
import Betgames from "../../../../assets/images/icons/menu-page/betgames.svg";
import Promotions from "../../../../assets/images/icons/menu/marketing.png";
import Blog from "../../../../assets/images/icons/menu-page/blog.svg";
import Supports from "../../../../assets/images/icons/menu-page/support.png";
import Qtech from "../../../../assets/images/icons/menu-page/qtech.png";
import numberWithCommas from "../../../../utils/numbersWithComma";

import WhatsappIcons from "../../../../assets/images/icons/header/Whatsapp.png";
import DepositIcons from "../../../../assets/images/icons/header/Deposit.png";
import AccountIcons from "../../../../assets/images/icons/header/AccountStatement.png";
import WithdrawIcons from "../../../../assets/images/icons/header/Withdrawals.png";
import BalanceIcon from "../../../../assets/images/icons/header/Wallet.png";
import CashiIcon from "../../../../assets/images/icons/cash.png";
import BonusIcon from "../../../../assets/images/icons/gift.png";

const Menu = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);

  return (
    <div className="my-menu">
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main HeaderMain">
        <div className="menu-page">
          <div className="menu-container">
            <div className="menu">
              <div className="MobileBal">
                <div className="BalanceBtn">
                  <div className="balance-info">
                    <div className="balance">
                      <div className="bonus-bal">
                        <img src={BalanceIcon} alt="BalanceIcon" />
                        <span>Balance:</span>{" "}
                        <span>{numberWithCommas(wallet?.balance)}</span>
                      </div>
                      <div className="bonus-bal">
                        <img src={CashiIcon} alt="BalanceIcon" />
                        <span>Cash:</span>{" "}
                        <span>{numberWithCommas(wallet?.coins)}</span>
                      </div>
                      <div className="bonus-bal">
                        <img src={BonusIcon} alt="BalanceIcon" />
                        <span>Bonus:</span>{" "}
                        <span>{numberWithCommas(wallet?.bonus)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="MobileMenu">
                  <ul>
                    <li>
                      {" "}
                      <a className="" href="/gatewaylist">
                        <img src={DepositIcons} alt="" />
                        Deposit
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a className="" href="/withdraw">
                        <img src={WithdrawIcons} alt="" />
                        Withdraw
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a className="" href="/cashier">
                        <img src={AccountIcons} alt="" />
                        Account Statement
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "#"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={WhatsappIcons} alt="" />
                        Whatsapp
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <a href="/mybets">
                <img src={Mybets} alt="sports" />
                <span>My Bets</span>
              </a>
              <a href="/referral">
                <img src={Referral} alt="exchange" />
                <span>Referral</span>
              </a>
              <a href="/bonus">
                <img src={Bonus} alt="casino" />
                <span>Bonus</span>
              </a>
              <a href="javascript:void(0)">
                <img src={Promotions} alt="promotions" />
                <span>Promotions</span>
              </a>
            </div>
          </div>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </div>
  );
};

export default Menu;
