export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  topgames: {
    cards32Top: {
      redirectUrl: "/casino/ezg-32-cards",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/32-cards.jpg",
      alt: "",
      name: "32 cards",
      providerName: "Ezugi",
    },
    BetOnTeenPattiTop: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/bet-on-teen-patti.jpg",
      alt: "",
      name: "Bet On Teen Patti",
      providerName: "Ezugi",
    },
    CasinoMarinaRoulette1Top: {
      redirectUrl: "/casino/ezg-casino-marina-roulette-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/casino-marina-roulette-1.jpg",
      alt: "",
      name: "Casino Marina Roulette 1",
      providerName: "Ezugi",
    },
    Lucky7Top: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/lucky-7.jpg",
      alt: "",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    OneDayTeenPattiTop: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/one-day-teen-patti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    SicBoTop: {
      redirectUrl: "/casino/ezg-sic-bo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/sic-bo.jpg",
      alt: "",
      name: "Sic Bo",
      providerName: "Ezugi",
    },
    Super6BaccaratTop: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/super-6-baccarat.jpg",
      alt: "",
      name: "Super 6 Baccarat",
      providerName: "Ezugi",
    },
    UltimateAndarBaharTop: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/ultimate-andar-bahar.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateSicBoTop: {
      redirectUrl: "/casino/ezg-ultimate-sic-bo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/top-games/ezugi/ultimate-sic-bo.jpg",
      alt: "",
      name: "Ultimate Sic Bo",
      providerName: "Ezugi",
    },

    CashOrCrashTop: {
      redirectUrl: "/casino/ezgevo-cash-or-crash",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/cash-or-crash.jpg",
      alt: "",
      name: "Cash Or Crash",
      providerName: "Evolution",
    },
    CasinoHoldemEVOTop: {
      redirectUrl: "/casino/ezgevo-casino-holdem-evo",
      code: "1000073",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/evolution/casino-holdem-evo.jpg",
      alt: "",
      name: "Casino Hold'em-EVO",
      providerName: "Evolution",
    },

    CrazyTimeTop: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/crazy-time.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    DreamCatcherTop: {
      redirectUrl: "/casino/ezgevo-dream-catcher",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/dream-catcher.jpg",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },

    ImmersiveRouletteTop: {
      redirectUrl: "/casino/ezgevo-immersive-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/immersive-roulette.jpg",
      alt: "",
      name: "Immersive Roulette",
      providerName: "Evolution",
    },
    LightningDiceTop: {
      redirectUrl: "/casino/ezgevo-lightning-dice",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/evolution/lightning-dice.jpg",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    LightningRouletteTop: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/lightning-roulette.jpg",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    MegaBallTop: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/mega-ball.jpg",
      alt: "",
      name: "Mega Ball",
      providerName: "Evolution",
    },
    TexasHoldemBonusPokerTop: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "1000073",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/evolution/texas-holdem-bonus.jpg",
      alt: "",
      name: "Texas Hold'em Bonus Poker",
      providerName: "Evolution",
    },

    Card2Teenpatti: {
      code: "67660",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "2 Card Teenpatti",
      name: "2 Card Teenpatti",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/2-card-teenpatti.jpg",
      redirectUrl: "/casino/aura-2-card-teenpatti",
    },
    Poker2020: {
      code: "67567",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Poker 20-20",
      name: "Poker 20-20",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/poker-20-20.jpg",
      redirectUrl: "/casino/aura-poker-20-20",
    },
    QueenRace: {
      code: "67620",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Queen Race",
      name: "Queen Race",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/queen-race.jpg",
      redirectUrl: "/casino/aura-queen-race",
    },
    TeenpattiOneDay: {
      code: "56767",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "teenpatti one day",
      name: "Teenpatti One Day",
      providerName: "aura",
      imgUrl: "./images/aura/improved-experience/teenpatti-one-day.jpg",
      redirectUrl: "/casino/aura-teenpatti-1-Day",
    },
    Cards3Judgement: {
      code: "67670",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "3 Cards Judgement",
      name: "3 Cards Judgement",
      providerName: "aura",
      imgUrl: "./images/aura/card/3-cards-judgement.jpg",
      redirectUrl: "/casino/aura-3-cards-judgement",
    },
    Card29Baccarat: {
      code: "67690",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "29 Card Baccarat",
      name: "29 Card Baccarat",
      providerName: "aura",
      imgUrl: "./images/aura/card/29-card-baccarat.jpg",
      redirectUrl: "/casino/aura-29-card-baccarat",
    },
    Matka: {
      code: "92037",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Matka",
      name: "Matka",
      providerName: "aura",
      imgUrl: "./images/aura/card/matka.jpg",
      redirectUrl: "/casino/aura-matka",
    },
    Sixplayerpoker: {
      code: "67565",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Six player poker",
      name: "Six player poker",
      providerName: "aura",
      imgUrl: "./images/aura/card/six-player-poker.jpg",
      redirectUrl: "/casino/aura-six-player-poker",
    },
    SuperOver: {
      code: "67720",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Super Over",
      name: "Super Over",
      providerName: "aura",
      imgUrl: "./images/aura/card/super-over.jpg",
      redirectUrl: "/casino/aura-sper-over",
    },
    TeenpattiOpen: {
      code: "67640",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Teenpatti Open",
      name: "Teenpatti Open",
      providerName: "aura",
      imgUrl: "./images/aura/card/teenpatti-open.jpg",
      redirectUrl: "/casino/aura-teenpatti-open",
    },
    Up7NDownVirtual: {
      code: "98793",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "7 up & Down (Virtual)",
      name: "7 up & Down (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/7-up-&-down.jpg",
      redirectUrl: "/casino/aura-7-up-&-down-virtual",
    },
    AmarAkbarAnthonyVirtual: {
      code: "98795",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Amar Akbar Anthony (Virtual)",
      name: "Amar Akbar Anthony (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/amar-akbar-anthony.jpg",
      redirectUrl: "/casino/aura-amar-akbar-anthony-virtual",
    },
    CasinoMeterVirtual: {
      code: "67576",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Casino Meter (Virtual)",
      name: "Casino Meter (Virtual)",
      providerName: "aura",
      imgUrl: "./images/aura/virtual/casino-meter.jpg",
      redirectUrl: "/casino/aura-casino-meter-virtual",
    },
    Aviator: {
      code: "67722",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Aviator",
      name: "Aviator",
      providerName: "aura",
      imgUrl: "./images/aura/instant/aviator.jpg",
      redirectUrl: "/casino/aura-aviator",
    },
    Blastoff: {
      code: "67722",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Blastoff",
      name: "Blastoff",
      providerName: "aura",
      imgUrl: "./images/aura/instant/blastoff.jpg",
      redirectUrl: "/casino/aura-blastoff",
    },
    FortuneWheel: {
      code: "70001",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Fortune Wheel",
      name: "Fortune Wheel",
      providerName: "aura",
      imgUrl: "./images/aura/instant/fortune-wheel.jpg",
      redirectUrl: "/casino/aura-fortune-wheel",
    },
    ClassicDice: {
      code: "70009",
      casino: "aura",
      provider: "aura",
      homeUrl: "http://localhost:3000/Casino",
      alt: "Classic Dice",
      name: "Classic Dice",
      providerName: "aura",
      imgUrl: "./images/aura/instant/classic-dice.jpg",
      redirectUrl: "/casino/aura-classic-dice",
    },

    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/andar-bahar/andar-bahar.jpg",
      alt: "andar bahar",
      name: "Andar Bahar",
      providerName: "Vivo",
    },
    TeenPattiVivo: {
      redirectUrl: "/casino/vivo-teen-patti",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/teen-patti/teen-patti.jpg",
      alt: "teen patti",
      name: "Teen Patti",
      providerName: "Vivo",
    },
    GalaxyRouletteVivo: {
      redirectUrl: "/casino/vivo-galaxy-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/galaxy-roulette.jpg",
      alt: "galaxy roulette",
      name: "Galaxy Roulette",
      providerName: "Vivo",
    },
    LasVegasRouletteVivo: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/las-vegas-roulette.jpg",
      alt: "las vegas roulette",
      name: "Las Vegas Roulette",
      providerName: "Vivo",
    },
    MacauBaccarat: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/macau-baccarat.jpg",
      alt: "macau baccarat",
      name: "Macau Baccarat",
      providerName: "Vivo",
    },
    VaBacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/va-bacarat-1.jpg",
      alt: "va bacarat 1",
      name: "Va Bacarat 1",
      providerName: "Vivo",
    },
  },

  baccarattab: {
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/baccarat.jpg",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat1: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/casino-marina-baccarat-1.jpg",
      alt: "",
      name: "Casino Marina Baccarat 1",
      providerName: "Ezugi",
    },
    KnockOutBaccarat: {
      redirectUrl: "/casino/ezg-knock-out-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/knock-out-baccarat.jpg",
      alt: "",
      name: "Knock Out Baccarat",
      providerName: "Ezugi",
    },
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/no-commission-baccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    Super6Baccarat: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/ezugi/super-6-baccarat.jpg",
      alt: "",
      name: "Super 6 Baccarat",
      providerName: "Ezugi",
    },

    galaxyBaccarat1: {
      redirectUrl: "/casino/vivo-galaxy-baccarat-1",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/galaxy-baccarat-1.jpg",
      alt: "galaxy baccarat 1",
      name: "Galaxy Baccarat 1",
      providerName: "Vivo",
    },
    galaxyBaccarat2: {
      redirectUrl: "/casino/vivo-galaxy-baccarat-2",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/galaxy-baccarat-2.jpg",
      alt: "galaxy baccarat 2",
      name: "Galaxy Baccarat 2",
      providerName: "Vivo",
    },
    galaxyBaccarat3: {
      redirectUrl: "/casino/vivo-galaxy-baccarat-3",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/galaxy-baccarat-3.jpg",
      alt: "galaxy baccarat 3",
      name: "Galaxy Baccarat 3",
      providerName: "Vivo",
    },
    MacauBaccarat: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/macau-baccarat.jpg",
      alt: "macau baccarat",
      name: "Macau Baccarat",
      providerName: "Vivo",
    },
    VaBacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/va-bacarat-1.jpg",
      alt: "va bacarat 1",
      name: "Va Bacarat 1",
      providerName: "Vivo",
    },
    VaBacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/va-bacarat-2.jpg",
      alt: "va bacarat 2",
      name: "Va Bacarat 2",
      providerName: "Vivo",
    },
    VaBacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/va-bacarat-3.jpg",
      alt: "va bacarat 3",
      name: "Va Bacarat 3",
      providerName: "Vivo",
    },
    VaBacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/va-bacarat-4.jpg",
      alt: "va bacarat 4",
      name: "Va Bacarat 4",
      providerName: "Vivo",
    },
    VaBacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/baccarat/va-bacarat-5.jpg",
      alt: "va bacarat 5",
      name: "Va Bacarat 5",
      providerName: "Vivo",
    },
  },

  blackjacktab: {
    Blackjack1: {
      redirectUrl: "/casino/ezg-blackjack-1",
      code: "1000070",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/blackjack-1.jpg",
      alt: "",
      name: "Blackjack 1",
      providerName: "Ezugi",
    },
    SalonPriveBlackjack: {
      redirectUrl: "/casino/ezg-salon-prive-blackjack",
      code: "1000070",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ezugi/salon-prive-blackjack.jpg",
      alt: "",
      name: "Salon Prive Blackjack",
      providerName: "Ezugi",
    },

    BlackjackParty: {
      redirectUrl: "/casino/ezgevo-blackjack-party",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-party.jpg",
      alt: "",
      name: "Blackjack Party",
      providerName: "Evolution",
    },
    BlackjackSilverB: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-b",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-silver-b.jpg",
      alt: "",
      name: "Blackjack Silver B",
      providerName: "Evolution",
    },
    BlackjackSilverC: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-c",
      code: "1000044",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-silver-c.jpg",
      alt: "",
      name: "Blackjack Silver C",
      providerName: "Evolution",
    },
    BlackjackSilverD: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-d",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-silver-d.jpg",
      alt: "",
      name: "Blackjack Silver D",
      providerName: "Evolution",
    },
    BlackjackSilverE: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-e",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-silver-e.jpg",
      alt: "",
      name: "Blackjack Silver E",
      providerName: "Evolution",
    },
    BlackjackSilverF: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-f",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-silver-f.jpg",
      alt: "",
      name: "Blackjack Silver F",
      providerName: "Evolution",
    },
    BlackjackSilverG: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-g",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-silver-g.jpg",
      alt: "",
      name: "Blackjack Silver G",
      providerName: "Evolution",
    },
    BlackjackVip1: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-1",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-1.jpg",
      alt: "",
      name: "Blackjack VIP 1",
      providerName: "Evolution",
    },
    BlackjackVip5: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-5",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-5.jpg",
      alt: "",
      name: "Blackjack VIP 5",
      providerName: "Evolution",
    },
    BlackjackVip7: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-7",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-7.jpg",
      alt: "",
      name: "Blackjack VIP 7",
      providerName: "Evolution",
    },
    BlackjackVip8: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-8",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-8.jpg",
      alt: "",
      name: "Blackjack VIP 8",
      providerName: "Evolution",
    },
    BlackjackVip11: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-11",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-11.jpg",
      alt: "",
      name: "Blackjack VIP 11",
      providerName: "Evolution",
    },
    BlackjackVip12: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-12",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-12.jpg",
      alt: "",
      name: "Blackjack VIP 12",
      providerName: "Evolution",
    },
    BlackjackVip16: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-16",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-16.jpg",
      alt: "",
      name: "Blackjack VIP 16",
      providerName: "Evolution",
    },
    BlackjackVipA: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-a.jpg",
      alt: "",
      name: "Blackjack VIP A",
      providerName: "Evolution",
    },
    BlackjackVipD: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-d",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-d.jpg",
      alt: "",
      name: "Blackjack VIP D",
      providerName: "Evolution",
    },
    BlackjackVipE: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-e",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-e.jpg",
      alt: "",
      name: "Blackjack VIP E",
      providerName: "Evolution",
    },
    BlackjackVipI: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-i",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-i.jpg",
      alt: "",
      name: "Blackjack VIP I",
      providerName: "Evolution",
    },
    BlackjackVipJ: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-j",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-j.jpg",
      alt: "",
      name: "Blackjack VIP J",
      providerName: "Evolution",
    },
    BlackjackVipL: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-l",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-l.jpg",
      alt: "",
      name: "Blackjack VIP L",
      providerName: "Evolution",
    },
    BlackjackVipP: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-p",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-p.jpg",
      alt: "",
      name: "Blackjack VIP P",
      providerName: "Evolution",
    },
    BlackjackVipQ: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-q",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-q.jpg",
      alt: "",
      name: "Blackjack VIP Q",
      providerName: "Evolution",
    },
    BlackjackVipS: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-s",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-s.jpg",
      alt: "",
      name: "Blackjack VIP S",
      providerName: "Evolution",
    },
    BlackjackVipT: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-t",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/blackjack-vip-t.jpg",
      alt: "",
      name: "Blackjack VIP T",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack3: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-3",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/classic-speed-blackjack-3.jpg",
      alt: "",
      name: "Classic Speed Blackjack 3",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack37: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-37",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/classic-speed-blackjack-37.jpg",
      alt: "",
      name: "Classic Speed Blackjack 37",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack39: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-39",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/classic-speed-blackjack-39.jpg",
      alt: "",
      name: "Classic Speed Blackjack 39",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack41: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-41",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/classic-speed-blackjack-41.jpg",
      alt: "",
      name: "Classic Speed Blackjack 41",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack42: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-42",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/classic-speed-blackjack-42.jpg",
      alt: "",
      name: "Classic Speed Blackjack 42",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack43: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-43",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/classic-speed-blackjack-43.jpg",
      alt: "",
      name: "Classic Speed Blackjack 43",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack51: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-51",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/classic-speed-blackjack-51.jpg",
      alt: "",
      name: "Classic Speed Blackjack 51",
      providerName: "Evolution",
    },
    ClassicSpeedBlackjack52: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-52",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/classic-speed-blackjack-52.jpg",
      alt: "",
      name: "Classic Speed Blackjack 52",
      providerName: "Evolution",
    },
    InfiniteBlackjack: {
      redirectUrl: "/casino/ezgevo-infinite-blackjack",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/infinite-blackjack.jpg",
      alt: "",
      name: "infinite Blackjack",
      providerName: "Evolution",
    },
    SpeedVipBlackjackM: {
      redirectUrl: "/casino/ezgevo-speed-vip-blackjack-m",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/speed-blackjack-m.jpg",
      alt: "",
      name: "Speed VIP Blackjack M",
      providerName: "Evolution",
    },
    SpeedVipBlackjackK: {
      redirectUrl: "/casino/ezgevo-speed-vip-blackjack-k",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/evolution/speed-blackjack-k.jpg",
      alt: "",
      name: "Speed VIP Blackjack K",
      providerName: "Evolution",
    },

    BlackjackVivo: {
      redirectUrl: "/casino/vivo-blackjack",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/blackjack/blackjack.jpg",
      alt: "blackjack",
      name: "Blackjack",
      providerName: "Vivo",
    },
    LasVegasVlackjack: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/blackjack/las-vegas-blackjack.jpg",
      alt: "las vegas blackjack",
      name: "Las Vegas Blackjack",
      providerName: "Vivo",
    },
    LimitlessBlackjack: {
      redirectUrl: "/casino/vivo-limitless-blackjack",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/blackjack/limitless-blackjack.jpg",
      alt: "limitless blackjack",
      name: "Limitless Blackjack",
      providerName: "Vivo",
    },
  },

  roulettetab: {
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/auto-roulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ezugi/ultimate-roulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },

    AmericanRoulette: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/american-roulette.jpg",
      alt: "",
      name: "American Roulette",
      providerName: "Evolution",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/apeed-auto-roulette.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    DoubleBallRoulette: {
      redirectUrl: "/casino/ezgevo-double-ball-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/double-ball-roulette.jpg",
      alt: "",
      name: "Double Ball Roulette Roulette",
      providerName: "Evolution",
    },
    FirstPersonAmericanRoulette: {
      redirectUrl: "/casino/ezgevo-first-person-american-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/first-person-american-roulette.jpg",
      alt: "",
      name: "First Person American Roulette",
      providerName: "Evolution",
    },
    FirstPersonLightningRoulette: {
      redirectUrl: "/casino/ezgevo-first-person-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/first-person-lightning-roulette.jpg",
      alt: "",
      name: "First Person Lightning Roulette",
      providerName: "Evolution",
    },
    ImmersiveRoulette: {
      redirectUrl: "/casino/ezgevo-immersive-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/immersive-roulette.jpg",
      alt: "",
      name: "Immersive Roulette",
      providerName: "Evolution",
    },
    InstantRoulette: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/instant-roulette.jpg",
      alt: "",
      name: "Instant Roulette",
      providerName: "Evolution",
    },
    LightningRoulette: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/lightning-roulette.jpg",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },
    LightningTurkiceRoulette: {
      redirectUrl: "/casino/ezgevo-turkish-lightning-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/lightning-turkice-roulette.jpg",
      alt: "",
      name: "Lightning Turkice Roulette",
      providerName: "Evolution",
    },
    RouletteEvo: {
      redirectUrl: "/casino/ezgevo-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/roulette.jpg",
      alt: "",
      name: "Roulette",
      providerName: "Evolution",
    },
    SalonPriveRoulette: {
      redirectUrl: "/casino/ezgevo-salon-prive-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/salon-prive-roulette.jpg",
      alt: "",
      name: "Salon Privé Roulette",
      providerName: "Evolution",
    },
    SpeedRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/speed-roulette.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    VipRoulette: {
      redirectUrl: "/casino/ezgevo-vip-roulette",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/evolution/vip-roulette.jpg",
      alt: "",
      name: "VIP Roulette",
      providerName: "Evolution",
    },

    EuropeanAautoRouletteVivo: {
      redirectUrl: "/casino/vivo-european-auto-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/european-auto-roulette.jpg",
      alt: "european auto roulette",
      name: "European Auto Roulette",
      providerName: "Vivo",
    },
    EuropeanouletteVivo: {
      redirectUrl: "/casino/vivo-european-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/european-roulette.jpg",
      alt: "european roulette",
      name: "European Roulette",
      providerName: "Vivo",
    },
    FrenchRouletteVivo: {
      redirectUrl: "/casino/vivo-french-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/french-roulette.jpg",
      alt: "french roulette",
      name: "French Roulette",
      providerName: "Vivo",
    },
    GalaxyRouletteVivo: {
      redirectUrl: "/casino/vivo-galaxy-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/galaxy-roulette.jpg",
      alt: "galaxy roulette",
      name: "Galaxy Roulette",
      providerName: "Vivo",
    },
    LasVegasRouletteVivo: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/las-vegas-roulette.jpg",
      alt: "las vegas roulette",
      name: "Las Vegas Roulette",
      providerName: "Vivo",
    },
    Oracle360RouletteVivo: {
      redirectUrl: "/casino/vivo-oracle-360-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/oracle-360-roulette.jpg",
      alt: "oracle 360 roulette",
      name: "Oracle 360 Roulette",
      providerName: "Vivo",
    },
    OracleBlazeRouletteVivo: {
      redirectUrl: "/casino/vivo-oracle-blaze-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/oracle-blaze-roulette.jpg",
      alt: "oracle blaze roulette",
      name: "Oracle Blaze Roulette",
      providerName: "Vivo",
    },
    OrientalRouletteVivo: {
      redirectUrl: "/casino/vivo-oriental-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/oriental-roulette.jpg",
      alt: "oriental roulette",
      name: "Oriental Roulette",
      providerName: "Vivo",
    },
    PortomasoRouletteVivo: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/portomaso-roulette.jpg",
      alt: "portomaso roulette",
      name: "Portomaso Roulette",
      providerName: "Vivo",
    },
    VaRouletteVivo: {
      redirectUrl: "/casino/vivo-va-roulette",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/roulette/va-roulette.jpg",
      alt: "va roulette",
      name: "Va Roulette",
      providerName: "Vivo",
    },
  },

  andarbahar: {
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andar-bahar/ezugi/andar-bahar.jpg",
      alt: "andar bahar",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    CasinoMarinaAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andar-bahar/ezugi/casino-marina-andar-bahar.jpg",
      alt: "casino marina andar bahar",
      name: "Casino Marina Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andar-bahar/ezugi/ultimate-andar-bahar.jpg",
      alt: "ultimate",
      name: "Ultimate",
      providerName: "Ezugi",
    },

    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/andar-bahar/andar-bahar.jpg",
      alt: "andar bahar",
      name: "Andar Bahar",
      providerName: "Vivo",
    },
  },

  pokertab: {
    CasinoHoldemEVO: {
      redirectUrl: "/casino/ezgevo-casino-holdem-evo",
      code: "1000073",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/evolution/casino-holdem-evo.jpg",
      alt: "",
      name: "Casino Hold'em-EVO",
      providerName: "Evolution",
    },
    TexasHoldemBonusPoker: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "1000073",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/evolution/texas-holdem-bonus.jpg",
      alt: "",
      name: "Texas Hold'em Bonus Poker",
      providerName: "Evolution",
    },

    TeenPattiVivo: {
      redirectUrl: "/casino/vivo-teen-patti",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/teen-patti/teen-patti.jpg",
      alt: "teen patti",
      name: "Teen Patti",
      providerName: "Vivo",
    },
    CasinoHoldemVivo: {
      redirectUrl: "/casino/vivo-casino-holdem",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/poker/casino-holdem.jpg",
      alt: "casino holdem",
      name: "Casino Holdem",
      providerName: "Vivo",
    },
  },

  gameshows: {
    CashOrCrash: {
      redirectUrl: "/casino/ezgevo-cash-or-crash",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/cash-or-crash.jpg",
      alt: "",
      name: "Cash Or Crash",
      providerName: "Evolution",
    },
    CrazyTime: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/crazy-time.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    DeadOrAliveSaloon: {
      redirectUrl: "/casino/ezgevo-dead-or-alive-saloon",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/dead-or-alive-saloon.jpg",
      alt: "",
      name: "Dead Or Alive: Saloon",
      providerName: "Evolution",
    },
    DreamCatcher: {
      redirectUrl: "/casino/ezgevo-dream-catcher",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/dream-catcher.jpg",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    FirstPersonDreamCatcher: {
      redirectUrl: "/casino/ezgevo-first-person-dream-catcher",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/first-person-dream-catcher.jpg",
      alt: "",
      name: "First Person Dream Catcher",
      providerName: "Evolution",
    },
    FirstPersonMegaBall: {
      redirectUrl: "/casino/ezgevo-first-person-mega-ball",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/first-person-mega-ball.jpg",
      alt: "",
      name: "First Person Mega Ball",
      providerName: "Evolution",
    },
    FootballStudio: {
      redirectUrl: "/casino/ezgevo-football-studio",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/football-studio.jpg",
      alt: "",
      name: "Football Studio",
      providerName: "Evolution",
    },
    MegaBall: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/mega-ball.jpg",
      alt: "",
      name: "Mega Ball",
      providerName: "Evolution",
    },
    SideBetCity: {
      redirectUrl: "/casino/ezgevo-side-bet-city",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/side-bet-city.jpg",
      alt: "",
      name: "Side Bet City",
      providerName: "Evolution",
    },
    StockMarket: {
      redirectUrl: "/casino/ezgevo-stock-market",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/game-show/stock-market.jpg",
      alt: "",
      name: "Stock Market",
      providerName: "Evolution",
    },

    DragonTigerVivo: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      code: "",
      casino: "vivo",
      provider: "Vivo",
      homeUrl: "http://localhost:3000/home",
      imgUrl: "./images/vivo/dragon-tiger/dragon-tiger.jpg",
      alt: "dragon tiger",
      name: "Dragon Tiger",
      providerName: "Vivo",
    },
  },

  supernowa: {
    Player3TeenPattiSN: {
      redirectUrl: "/casino/sn-3-player-teen-patti",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/3-player-teen-patti.jpg",
      alt: "3 player teen patti",
      name: "3 Player Teen Patti",
      providerName: "Supernowa",
    },
    Cards32SN: {
      redirectUrl: "/casino/sn-32-cards",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/32-cards.jpg",
      alt: "32 cards",
      name: "32 Cards",
      providerName: "Supernowa",
    },
    AkbarRomeoWalterSN: {
      redirectUrl: "/casino/sn-akbar-romeo-walter",
      code: "RCKT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/akbar-romeo-walter.jpg",
      alt: "akbar romeo walter",
      name: "Akbar Romeo Walter",
      providerName: "Supernowa",
    },
    AndarBaharSN: {
      redirectUrl: "/casino/sn-andar-bahar",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/andar-bahar.jpg",
      alt: "andar bahar",
      name: "Andar Bahar",
      providerName: "Supernowa",
    },
    BaccaratSN: {
      redirectUrl: "/casino/sn-baccarat",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/Baccarat.jpg",
      alt: "baccarat",
      name: "Baccarat",
      providerName: "Supernowa",
    },
    CasinoQueen: {
      redirectUrl: "/casino/sn-casino-queen",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/casino-queen.jpg",
      alt: "casino queen",
      name: "Casino Queen",
      providerName: "Supernowa",
    },
    ClassicAndarBaharSN: {
      redirectUrl: "/casino/sn-classic-andar-bahar",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/classic-andar-bahar.jpg",
      alt: "classic andar bahar",
      name: "Classic Andar Bahar",
      providerName: "Supernowa",
    },
    DragonTigerSN: {
      redirectUrl: "/casino/sn-dragon-tiger",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/dragon-tiger.jpg",
      alt: "dragon tiger",
      name: "Dragon Tiger",
      providerName: "Supernowa",
    },
    GoasAndarBaharSN: {
      redirectUrl: "/casino/sn-goas-andar-bahar",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/goas-andar-bahar.jpg",
      alt: "goas andar bahar",
      name: "Goas Andar Bahar",
      providerName: "Supernowa",
    },
    KingRaceSN: {
      redirectUrl: "/casino/sn-king-race",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/king-race.jpg",
      alt: "king race",
      name: "King Race",
      providerName: "Supernowa",
    },
    Lucky7SN: {
      redirectUrl: "/casino/sn-lucky-7",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/lucky-7.jpg",
      alt: "lucky 7",
      name: "Lucky 7",
      providerName: "Supernowa",
    },
    PokerSN: {
      redirectUrl: "/casino/sn-poker",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/Poker.jpg",
      alt: "poker",
      name: "Poker",
      providerName: "Supernowa",
    },
    RouletteSN: {
      redirectUrl: "/casino/sn-roulette",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/Roulette.jpg",
      alt: "roulette",
      name: "Roulette",
      providerName: "Supernowa",
    },
    TeenPattiSN: {
      redirectUrl: "/casino/sn-teen-patti",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/Teen-Patti.jpg",
      alt: "teen patti",
      name: "Teen Patti",
      providerName: "Supernowa",
    },
    TeenPatti2020SN: {
      redirectUrl: "/casino/sn-teen-patti-2020",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/teen-patti-2020.jpg",
      alt: "teen patti 2020",
      name: "Teen Patti 2020",
      providerName: "Supernowa",
    },
    TeenPattiFaceOffSN: {
      redirectUrl: "/casino/sn-teen-patti-face-off",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/teen-patti-face-off.jpg",
      alt: "teen patti face off",
      name: "Teen Patti Face Off",
      providerName: "Supernowa",
    },
    WorliMatkaSN: {
      redirectUrl: "/casino/sn-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/worli-matka.jpg",
      alt: "worli matka",
      name: "Worli Matka",
      providerName: "Supernowa",
    },
    RNG3CardsJudgement: {
      redirectUrl: "/casino/sn-rng-3-cards-judgement",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-3-cards-judgement.jpg",
      alt: "rng 3 cards judgement",
      name: "RNG 3 Cards Judgement",
      providerName: "Supernowa",
    },
    RNG3PlayerTeenPatti: {
      redirectUrl: "/casino/sn-rng-3-player-teen-patti",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-3-player-teen-patti.jpg",
      alt: "rng 3 player teen patti",
      name: "RNG 3 Player Teen Patti",
      providerName: "Supernowa",
    },
    RNG32Cards: {
      redirectUrl: "/casino/sn-rng-32-cards",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-32-cards.jpg",
      alt: "rng 32 cards",
      name: "RNG 32 Cards",
      providerName: "Supernowa",
    },
    RNGAkbarRomeoWalter: {
      redirectUrl: "/casino/sn-rng-akbar-romeo-walter",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-akbar-romeo-walter.jpg",
      alt: "rng akbar romeo walter",
      name: "RNG Akbar Romeo Walter",
      providerName: "Supernowa",
    },
    RNGBaccarat: {
      redirectUrl: "/casino/sn-rng-baccarat",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-baccarat.jpg",
      alt: "rng baccarat",
      name: "RNG baccarat",
      providerName: "Supernowa",
    },
    RNGBaccarat2020: {
      redirectUrl: "/casino/sn-rng-baccarat-2020",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-baccarat-2020.jpg",
      alt: "rng baccarat 2020",
      name: "RNG Baccarat 2020",
      providerName: "Supernowa",
    },
    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-casino-queen.jpg",
      alt: "rng casino queen",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-casino-queen-2020.jpg",
      alt: "rng casino queen 2020",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },
    RNGCricket2020: {
      redirectUrl: "/casino/sn-rng-cricket-2020",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-cricket-2020.jpg",
      alt: "rng cricket 2020",
      name: "RNG Cricket 2020",
      providerName: "Supernowa",
    },
    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
      alt: "rng dragon tiger",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-dragon-tiger-2020.jpg",
      alt: "rng dragon tiger 2020",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-joker.jpg",
      alt: "rng joker",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKabutarFuddi: {
      redirectUrl: "/casino/sn-rng-kabutar-fuddi",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-kabutar-fuddi.jpg",
      alt: "rng kabutar fuddi",
      name: "RNG Kabutar Fuddi",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-king-race.jpg",
      alt: "rng king race",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-lucky-7.jpg",
      alt: "rng lucky 7",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGPoker: {
      redirectUrl: "/casino/sn-rng-poker",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-poker.jpg",
      alt: "rng poker",
      name: "RNG Poker",
      providerName: "Supernowa",
    },
    RNGPoker2020: {
      redirectUrl: "/casino/sn-rng-poker-2020",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-poker-2020.jpg",
      alt: "rng poker 2020",
      name: "RNG Poker 2020",
      providerName: "Supernowa",
    },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-teen-patti.jpg",
      alt: "rng teen patti",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-teen-patti-2020.jpg",
      alt: "rng teen patti 2020",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorliMatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/rng-worli-matka.jpg",
      alt: "rng worli matka",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
  },

  livecasion: {
    Ezugi: {
      redirectUrl: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Ezugi.jpg",
      alt: "ezugi",
      name: "Ezugi",
      providerName: "Ezugi",
    },
    Evolution: {
      redirectUrl: "/casino/evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Evolution.jpg",
      alt: "evolution",
      name: "Evolution",
      providerName: "Evolution",
    },
    Vivo: {
      redirectUrl: "/casino/vivo",
      code: "",
      casino: "vivo",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Vivo.jpg",
      alt: "vivo",
      name: "Vivo",
      providerName: "Vivo",
    },
    XPG: {
      redirectUrl: "/casino/xpg",
      code: "",
      casino: "xpg",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/XPG.jpg",
      alt: "xpg",
      name: "XPG",
      providerName: "XPG",
    },
    Supernowa: {
      redirectUrl: "/casino/supernowa",
      code: "",
      casino: "supernowa",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Supernowa.jpg",
      alt: "supernowa",
      name: "Supernowa",
      providerName: "Supernowa",
    },
    Worldcasino: {
      redirectUrl: "/casino/worldcasino",
      code: "",
      casino: "worldcasino",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/WorldCasino.jpg",
      alt: "worldcasino",
      name: "Worldcasino",
      providerName: "Worldcasino",
    },
    Slots: {
      redirectUrl: "/Slots",
      code: "",
      casino: "slots",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Slots.jpg",
      alt: "slots",
      name: "Slots",
      providerName: "Slots",
    },
    AuraGaming: {
      redirectUrl: "/aura-games",
      code: "",
      casino: "aura",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/AuraGaming.jpg",
      alt: "aura",
      name: "Aura",
      providerName: "Aura",
    },
    KingMaker: {
      redirectUrl: "/kingmaker",
      code: "",
      casino: "kingmaker",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/KingMaker.jpg",
      alt: "kingmaker",
      name: "KingMaker",
      providerName: "KingMaker",
    },
    Spribe: {
      redirectUrl: "/spribe",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/providers/Spribe.jpg",
      alt: "spribe",
      name: "Spribe",
      providerName: "Spribe",
    },
  },
};
