import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Tab, Nav, Button } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import Bonus100 from "../../../../assets/images/banner/promotions/bonus-100.png";
import Bonus200 from "../../../../assets/images/banner/promotions/bonus-200.png";
import Bonus300 from "../../../../assets/images/banner/promotions/bonus-300.png";
import BonusMultiple from "../../../../assets/images/banner/promotions/bonus-multiple.png";
import MultipleInsurance from "../../../../assets/images/banner/promotions/multiple-insurance.png";
import DropsWins from "../../../../assets/images/banner/promotions/drops-wins.png";
import DropsWinsSlots from "../../../../assets/images/banner/promotions/drops-wins-slots.png";
import ArrowBack from "../../../../assets/images/icons/arrow-back.png";

const Promotions = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((state) => state.auth);

  const navItems = [
    {
      eventKey: "first",
      imgSrc: Bonus100,
      altText: "100 bonus",
      label: "Up to €100 on your first deposit!",
    },
    {
      eventKey: "second",
      imgSrc: Bonus200,
      altText: "200 bonus",
      label: "Bonus on your second deposit up to €200!",
    },
    {
      eventKey: "thired",
      imgSrc: Bonus300,
      altText: "300 bonus",
      label: "300€ bonus on your third deposit",
    },
    {
      eventKey: "fourth",
      imgSrc: BonusMultiple,
      altText: "multiple bonus",
      label: "Multiple bonus",
    },
    {
      eventKey: "fifth",
      imgSrc: MultipleInsurance,
      altText: "multiple insurance",
      label: "Multiple insurance",
    },
    {
      eventKey: "sixsth",
      imgSrc: DropsWins,
      altText: "drops & wins",
      label: "Pragmatic Drops & Wins (Live Casino) Tournament",
    },
    {
      eventKey: "seven",
      imgSrc: DropsWinsSlots,
      altText: "drops & wins slots",
      label: "Pragmatic Drops & Wins (Slots) Tournament",
    },
  ];
  const [show, setShow] = useState(true);
  const isMobile = window.screen.width > 767.98 ? false : true;
  const handleClick = () => {
    if (isMobile) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  const [active, setActive] = useState("first");

  const handleShow = () => {
    setShow((prev) => !prev);
    setActive(false);
  };

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="promotions">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={!isMobile && active}
          >
            <div className="tournaments">
              {show && (
                <div
                  className={show ? "tournaments-info d-block" : " d-none d-m"}
                >
                  <div className="header-title">
                    <a
                      href="javascript:void(0)"
                      onClick={() => navigate(-1)}
                      className="d-md-none"
                    >
                      <img src={ArrowBack} alt="back" />
                    </a>
                    <h2>Promotions</h2>
                  </div>
                  <div className="tournaments-promotions">
                    <Nav variant="pills">
                      {navItems.map((item, index) => (
                        <Nav.Item key={index} onClick={handleClick}>
                          <Nav.Link eventKey={item.eventKey}>
                            <img src={item.imgSrc} alt={item.altText} />
                            <h6>{item.label}</h6>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </div>
              )}

              <div className={show ? "tournaments-details" : "w-full"}>
                <Tab.Content>
                  {navItems.map((item, index) => (
                    <Tab.Pane eventKey={item.eventKey} key={index}>
                      <div className="header-title">
                        {isMobile && (
                          <a
                            href="javascript:void(0)"
                            onClick={handleShow}
                            className="d-md-none"
                          >
                            <img src={ArrowBack} alt="back" />
                          </a>
                        )}
                        <h2>{item.label}</h2>
                      </div>
                      <div className="img-box">
                        <img src={item.imgSrc} alt={item.altText} />
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </>
  );
};

export default Promotions;
